import { environment } from './../../../../environment/environment.uat';
import { Inject, Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { SECRET_KEY } from 'src/environment/environment.factory';
@Injectable({
  providedIn: 'root'
})
export class EncryptService {


  constructor(@Inject(SECRET_KEY) private sKey: string,) {}


  encrypt(payload: string): string {
    const iv = CryptoJS.lib.WordArray.random(16);
    const ciphertext = CryptoJS.AES.encrypt(payload, CryptoJS.enc.Utf8.parse(this.sKey), {
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    const ivBase64 = CryptoJS.enc.Base64.stringify(iv);
    const ciphertextBase64 = ciphertext.toString();
    return `${ivBase64}:${ciphertextBase64}`;
  }

  decrypt(encryptedData: string): string {
    const parts = encryptedData.split(':');
    const iv = CryptoJS.enc.Base64.parse(parts[0]);
    const ciphertext = CryptoJS.enc.Base64.parse(parts[1]);
    const decrypted = CryptoJS.AES.decrypt({
        ciphertext: ciphertext
    }, CryptoJS.enc.Utf8.parse(this.sKey), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
}


decrypt1(text: string, skey: string) {
  if (text && skey) {
    const parts = text.split(':');
    if (parts.length === 2) {
      const ivBase64 = parts[0];
      const encryptedBase64 = parts[1];
      const iv = CryptoJS.enc.Base64.parse(ivBase64);
      const encrypted = CryptoJS.enc.Base64.parse(encryptedBase64);
      const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext: encrypted }, CryptoJS.enc.Utf8.parse(skey), {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7 
      });
      const plaintext = decryptedBytes.toString(CryptoJS.enc.Utf8);
      return plaintext;
    } else {
      throw new Error('Invalid encrypted text format');
    }
  } else {
    return null;
  }
}


}
