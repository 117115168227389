<div class="global-container">
    <div class="input-container">
      <div class="input-content">
        <span class="input-heading m-2 mt-0">Requirement Description</span>
        <div class="">
          <textarea [(ngModel)]="requirementDescription" id="requiremnt_description"
            class="input-textarea" autocomplete="off" placeholder="Enter your requirements"></textarea>
        </div>
        <div class="pt-2">
          <span class="input-heading m-2 mt-0">Verification Criteria</span>
          <div class="pt-2">
            <textarea [(ngModel)]="verificationCriteria" id="verification_criteria"
              class="input-textarea-1" autocomplete="off" placeholder="Enter your requirements"></textarea>
          </div>
        </div>
        
        <!-- <div class="pt-4">
            <mat-form-field style="cursor: pointer;" appearance="outline" class="custom-mat-form-field example-full-width" (click)="fileInput.click()">
              <input matInput placeholder="Click here to upload" readonly>
              <button matSuffix mat-icon-button class="upload-btn-1" color="primary" id="New_Requirement_File_Upload_button">
                <img src="assets/images/new-icons/upload.svg" alt="Upload" class="upload-img">
              </button>
              <input #fileInput type="file" style="display: none" (change)="onFileSelected($event)" multiple accept=".csv,.xls,.xlsx,.pdf,.dbc">
            </mat-form-field>
            
            <div *ngIf="fileNamesDisplay.length > 0" class="uploaded-file-names">
              <ul>
                <li *ngFor="let fileName of fileNamesDisplay; let i = index">
                  {{ fileName }}
                  <button class="clear-file-btn" (click)="removeFile(i)">×</button>
                </li>
              </ul>
            </div>
            
        </div> -->
  
        <div class="btn-group">
          <button mat-raised-button class="generate-btn" [ngClass]="{'w-70a': requirementDescription}" id="Generate_Functional_Test_Cases_Generate_Button"
            (click)="onGenerate()" [disabled]="!requirementDescription">
            <span class="gen-text">Generate</span>
          </button>
          
          <button mat-raised-button class="clear-btn" id="Generate_Functional_Test_Cases_Clear_Button" (click)="onClear()"
            *ngIf="requirementDescription || requirementDescription != '' ">
            <span class="clear-text">Clear</span>
          </button>
        </div>
      </div>
    </div>
    
    <div class="output-container">
      <div class="empty" id="Generate_Functional_Test_Cases_Center_Text" *ngIf="!testCases.length && !isLoading">
        <img class="empty-img" src="assets/images/new-icons/empty.svg" />
        <span class="empty-description">Derive functional test cases from system requirements</span>
      </div>
      
      <div class="output-body" *ngIf="testCases.length">
        <div class="img-change" id="Functional_Test_Cases_classified_tableview">
          <img src="assets/images/frame1.svg" *ngIf="!isTable" (click)="onTable()" />
          <img src="assets/images/frame2.svg" *ngIf="isTable" (click)="onTable()" />
        </div>
  
        <!-- List View -->
        <div *ngIf="!isTable">
          <div class="output-item">
            <p class="h-text">Test Scenarios</p>
            <ul>
              <li *ngFor="let scenario of testScenarios">
                <span class="word-content">{{ scenario.Scenarios }}</span>
              </li>
            </ul>
          </div>
          
            <div *ngFor="let testCase of testCases" class="output-item" id="Generate_Functional_Test_Cases_Generated_Test_Case">
              <div class="headings">
                <span class="h-text">{{ testCase['Test Case Name'] }}</span>
              </div>
              <div class="test-case-details">
                <b>Objective:</b>
                <p>{{ testCase.Objective }}</p>
                <b>Precondition:</b>
                <ul>
                  <li>{{ testCase.Precondition }}</li>
                </ul>
                <b>Actions:</b>
                <ul>
                  <li *ngFor="let action of testCase.Action">{{ action }}</li>
                </ul>
                <b>Postcondition:</b>
                <p>{{ testCase.Postcondition }}</p>
                <b>Pass/Fail Criteria:</b>
                <p>{{ testCase['Pass/Fail Criteria'] }}</p>
              </div>
            </div>
        </div>
  
        <!-- Table View -->
        <div *ngIf="isTable">
          <div class="output-item">
            <p class="h-text">Test Scenarios</p>
            <ul>
              <li *ngFor="let scenario of testScenarios">
                <span class="word-content">{{ scenario.Scenarios }}</span>
              </li>
            </ul>
          </div>
         <div class="table-container" >
            <table id="taraTable">
              <thead>
                <tr>
                  <th class="testcase-column">Test Case Name</th>
                  <th>Objective</th>
                  <th>Precondition</th>
                  <th>Actions</th>
                  <th>Postcondition</th>
                  <th>Pass/Fail Criteria</th>
                  
                </tr>
              </thead>
              <tbody>
                <tr *ngFor=" let item of flatTestCases">
                  <td class="testcase-column"> {{item['Test Case Name']}}</td>
                  <td>{{item.Objective}}</td>
                  <td> {{item.Precondition}}</td>
                  <td>
                    <ul>
                      <li *ngFor="let action of item.Action">{{ action }}</li>
                    </ul> 
                  </td>          
                  <td>{{item.Postcondition}}</td>
                  <td>{{item['Pass/Fail Criteria']}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
        </div>
        
      </div>
      
      <div class="output-footer" *ngIf="testCases.length && !isTable">
        <button mat-raised-button class="copy-btn" id="Generate_Functional_Test_Cases_Copy_Button" (click)="copyText()">
          <span class="copy-text">Copy</span>
        </button>
      </div>
      <div class="output-footer" *ngIf="testCases.length&&isTable">
        <button mat-raised-button class="copy-btn" id="tara_Generated_Results_Export_To_CSV_Button" (click)="exportToExcel()">
          <span class="copy-text">Export to Excel</span>
        </button>
        <button mat-raised-button class="copy-btn" id="tara_Generated_Results_Export_To_CSV_Button" (click)="exportToCsv()">
          <span class="copy-text">Export to CSV</span>
        </button>
      </div>
    </div>
  </div>
  
  <div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader"></div>
    <div class="loading-text">{{ currentText }}</div>
  </div>
  