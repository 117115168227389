<div class="global-container">
    <div class="input-container">
        <div class="input-content">
            <span class="input-heading m-2 mt-0"> Group Requirement </span>
            <div class="">
                <textarea [(ngModel)]="extractedData" id="Group_Requirements_Enter_Your_Requirements" class="input-textarea" autocomplete="off"
                    placeholder="Enter your requirements"></textarea>
            </div>

            <div class="mt-3" *ngIf="industry == 2">
                <mat-form-field appearance="outline" class="custom-mat-form-field drop1">
                    <mat-label>Group By</mat-label>
                    <mat-select id="Group_By_Dropdown" (selectionChange)="onValueChange($event)">
                        <mat-option class="opt" *ngFor="let data of groupBy"
                            [value]="data.value" [id]="data.id">{{data.name}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>

            <div class="btn-group">
                <button mat-raised-button class="upload-btn" id="Upload_File_group_Requirements" (click)="openPopup()" *ngIf="!extractedData">
                    <img class="upload-img" src="assets/images/new-icons/upload.svg" />
                </button>
                <button mat-raised-button class="generate-btn"  id="Group_Button" [disabled]="industry == 2 ? (!selectedValue || !extractedData ) : !extractedData"
                     (click)="ongroupby()">
                    <span class="gen-text"> Group By </span>
                </button>
                <button mat-raised-button  id="Group_Requirements_clear_button" class="clear-btn" (click)="onClear()" *ngIf="extractedData">
                    <span class="clear-text"> Clear</span>
                </button>
            </div>
        </div>
    </div>
    <div class="output-container">
        <div class="img-change" id="Group_Requirements_classified_tableview">
            <img src="assets/images/tree.svg" *ngIf="!isTable && softwareRequirements" class="tree" (click)="onNodes()" />
        </div>
        <div class="empty" id="Group_Requirements_Center_Text" *ngIf="!softwareRequirements  && !isLoading">
            <img class="empty-img" src="assets/images/new-icons/empty.svg" />
            <span class="empty-description"> Group similar requirements together (e.g. vehicle or <br>
                SW feature based grouping)
            </span>
        </div>
        <div class="output-body">
            <div *ngIf="!isTable">
                <div class="output-item" id="Group_Requirements_grouped_Description"  *ngFor="let requirement of softwareRequirements">
                    <div class="headings">
                        <span class="h-text">{{ requirement.Group_name }}</span>
                    </div>
                    <ul class="numbered-list">
                        <li class="l-text" *ngFor="let data of requirement.requirements">{{
                            data.value}}</li>
                    </ul>
                </div>
            </div>

            <div  *ngIf="isTable" class="example-container pt-2">
                <table mat-table [dataSource]="softwareRequirements" class=" pt-4" id="Group_Requirements_grouped_tableview">

                    <!-- Domain Column -->
                    <ng-container matColumnDef="domain">
                        <th mat-header-cell *matHeaderCellDef class="header-text"> Domain </th>
                        <td mat-cell *matCellDef="let requirement" class="domain-column req"> {{requirement.Domain}} </td>
                    </ng-container>

                    <!-- System Column -->
                    <ng-container matColumnDef="system">
                        <th mat-header-cell *matHeaderCellDef class="header-text"> System </th>
                        <td mat-cell *matCellDef="let requirement" class="system-column req">{{requirement.System}}</td>
                    </ng-container>

                    <!-- Requirement Column -->
                    <ng-container matColumnDef="requirement">
                        <th mat-header-cell *matHeaderCellDef class="header-text"> Requirement </th>
                        <td mat-cell *matCellDef="let requirement" class="requirement-column req">
                            {{requirement.Requirement}} </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>
        </div>
        <div class="output-footer" *ngIf="softwareRequirements">
            <button mat-raised-button id="Group_Requirements_Export_CSV" class="copy-btn" *ngIf="isTable" (click)="exportToCsv()">
                <span class="copy-text">Export Csv</span>
            </button>
            <button mat-raised-button id="Group_Requirement_Copy_Button" class="copy-btn" *ngIf="!isTable" (click)="copyText()">
                <span class="copy-text">Copy</span>
            </button>
        </div>
    </div>
</div>


<div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader">
    </div> 
    <div class="loading-text">{{ currentText }}</div>
</div>