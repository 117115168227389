import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IalterDetails } from 'src/app/core/utils/interface/shared.interface';
import { ReviewRequirementsComponent } from '../../features/review-requirements/review-requirements.component';

@Component({
  selector: 'app-review-alternative',
  templateUrl: './review-alternative.component.html',
  styleUrls: ['./review-alternative.component.scss']
})

export class ReviewAlternativeComponent {
  dataSource: IalterDetails;
  replacedWords : any = null;
  selectedChip: string | null = null;
  isReplaceButtonActive: boolean = false;
  constructor(
    private digloagRef: MatDialogRef<ReviewRequirementsComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.dataSource = data;   
  }

  ngOnInit() {
    this.isReplaceButtonActive = this.dataSource.alternativeWords.length === 1;
    if (this.isReplaceButtonActive) {
      this.selectedChip = this.dataSource.alternativeWords[0];
      this.prepareReplacedWords();
    }
  }
  
  onSelectChips(selectedWord: string) {
    this.selectedChip = selectedWord;
    this.prepareReplacedWords();
  }
  
  prepareReplacedWords() {
    this.replacedWords = {
      originalWord: this.dataSource.modification_point,
      replaceWord: this.selectedChip
    };
    this.isReplaceButtonActive = true;
  }
  
  onReplace(){
    if(this.replacedWords){
      this.digloagRef.close(this.replacedWords);
    }
  }

onClose(){
   this.digloagRef.close(null);
}

  
}
