<div class="global-container">
    <div class="input-container">
        <div class="input-content">
            <span class="input-heading m-2 mt-0"> FMEA </span>
            <div class="">
                <textarea [(ngModel)]="extractedData" id="FMEA_Enter_Your_Requirements" class="input-textarea" autocomplete="off"
                    placeholder="Enter your requirements"></textarea>
            </div>
            <div class="btn-group">
                <button mat-raised-button class="upload-btn" id="FMEA_File_Upload_Button" (click)="openPopup()" *ngIf="!extractedData">
                    <img class="upload-img" src="assets/images/new-icons/upload.svg" />
                </button>
                <button mat-raised-button class="generate-btn" id="FMEA_Generate_Button" [disabled]="!extractedData" (click)="onGenerate()">
                    <span class="gen-text"> Generate</span>
                </button>
                <button mat-raised-button class="clear-btn" id="FMEA_Clear_Button" (click)="onClear()" *ngIf="extractedData">
                    <span class="clear-text"> Clear</span>
                </button>
            </div>
        </div>
    </div>
    <div class="output-container">
        <div class="empty" id="hazop_Center_Text" *ngIf="dataSource.length == 0 && !isLoading" >
            <img class="empty-img" src="assets/images/new-icons/empty.svg" />
            <span class="empty-description"> Analyze failure modes, theirs effects on the system <br> and their root causes of
                automotive systems
            </span>
        </div>
        <div class="output-body"  *ngIf="dataSource.length != 0" id="FMEA_Generated_Effects&_Mitigation">
      
            <div class="table-container" >
                <table id="hazopTable">
                  <thead>
                    <tr>
                      <th>Component</th>
                      <th>Function</th>
                      <th>Failure Mode</th>
                      <th>Effects</th>
                      <th>Root Causes</th>
                      <th>Mitigation Measures</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor=" let item of dataSource">
                      <td>{{item.component}}</td>
                      <td>{{item.function}}</td>
                      <td>{{item.failure_mode}}</td>
                      <td>{{item.effects}}</td>                      
                      <td>{{item.root_causes}}</td>
                      <td>{{item.mitigation_measures}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
        </div>
        <div class="output-footer" *ngIf="dataSource.length != 0">
            <button mat-raised-button class="copy-btn" id="FMEA_Generated_Effects&_Mitigation_Export_To_CSV_BUtton" (click)="exportToCsv()">
                <span class="copy-text">Export to CSV</span>
            </button>
        </div>
    </div>
</div>


<div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader">
    </div>
    <div class="loading-text">{{ currentText }}</div>
</div>