<section>
    <mat-stepper #stepper>
        <mat-step label="Upload">
            <ng-template matStepperIcon="edit" let-index="index" let-active="active" let-completed="completed">
                <ng-container *ngIf="completed || active; else stepNumber">{{ index + 1 }}</ng-container>
                <ng-template #stepNumber>{{ index + 1 }}</ng-template>
            </ng-template>
            <ng-container *ngTemplateOutlet="Upload" />
        </mat-step>
        <mat-step label="Review" [editable]="!isSkipped">
            <ng-template matStepperIcon="edit" let-index="index" let-active="active" let-completed="completed">
                <ng-container *ngIf="completed || active; else stepNumber">{{ index + 1 }}</ng-container>
                <ng-template #stepNumber>{{ index + 1 }}</ng-template>
            </ng-template>
            <ng-container *ngTemplateOutlet="Review" />
        </mat-step>
        <mat-step label="Test Cases">
            <ng-template matStepperIcon="edit" let-index="index" let-active="active" let-completed="completed">
                <ng-container *ngIf="completed || active; else stepNumber">{{ index + 1 }}</ng-container>
                <ng-template #stepNumber>{{ index + 1 }}</ng-template>
            </ng-template>
            <ng-container *ngTemplateOutlet="TestCase" />
        </mat-step>
    </mat-stepper>
</section>



<!-- Upload Component -->
<ng-template #Upload>
    <section>
        <div class="container-fluid d-flex flex-column vh-100">
            <div class="row flex-fill">
                <div class="col-12">
                    <div class="d-flex flex-column">
                        <div class="mb-2 mt-3" style="flex: 0 0 20%;">
                            <div class="card">
                                <div class="card-body" style="padding-left: 25px;">
                                    <div class="card-body" style="padding-left: 25px;">
                                        <!-- First row with 3 columns -->
                                        <div class="row" style="justify-content: space-between;">

                                            <div class="col-4">
                                                <!-- Second column content -->
                                                <label for="fileInput" class="outside-mat-label">Upload File</label>
                                                <mat-form-field style="cursor: pointer;" appearance="outline"
                                                    class="custom-mat-form-field example-full-width"
                                                    (click)="fileInput.click()">
                                                    <input matInput placeholder="Click here to upload" readonly>
                                                    <button matSuffix mat-icon-button class="upload-btn" color="primary"
                                                        id="New_Requirement_File_Upload_button">
                                                        <img src="assets/images/new-icons/upload.svg" alt="Upload"
                                                            class="upload-img">
                                                    </button>
                                                    <input type="file" #fileInput style="display: none"
                                                        (change)="onFileSelected($event)" accept=".csv,.xls,.xlsx">
                                                </mat-form-field>
                                                <div class="col-4 selected-toppings">
                                                    <span *ngFor="let name of fileNamesDisplay; let i = index"
                                                        class="file-name" [matTooltip]="name" [class.visible]="selectedFilesVisible"
                                                        [ngStyle]="{'max-width': fileNamesDisplay.length === 1 ? 'none' : '180px'}">
                                                        {{ name }}</span>
                                                </div>
                                            </div>

                                            <div class="col-4"
                                                style="display: flex;justify-content: end;align-items: center; flex-direction: column;">
                                                <!-- Third column content -->
                                                 <div>
                                                <button mat-raised-button class="generate-btn" *ngIf="!isSkipped"
                                                    [disabled]="fileNamesDisplay.length == 0 "
                                                    id="Compare_Requirements_Compare_Button" (click)='onCompare()'>
                                                    <span class="gen-text">Review</span>
                                                </button>
                                                <button mat-raised-button class="generate-btn" *ngIf="isSkipped"
                                                    [disabled]="fileNamesDisplay.length == 0 " (click)="onSkipReview()">
                                                    <span class="gen-text">Generate TC</span>
                                                </button>
                                                <button mat-raised-button class="clear-btn"
                                                    id="Compare_Requirements_Clear_Button" (click)="onClear()"
                                                    [disabled]="fileNamesDisplay.length == 0 ">
                                                    <span class="clear-text">Clear</span>
                                                </button>
                                            </div>
                                            <div class="skip-rev">
                                                <div style="width: 65%;" class="d-flex justify-content-center">
                                                    <input type="checkbox" id="skip-review" name="skip" value="skip" 
                                                    (change)="onSkipReviewChange($event)" [checked]="isSkipped">
                                                    <label for="skip-review" class="ms-1">Skip Review</label><br>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

        <div *ngIf="isLoading" class="loader-container">
            <div class="ring-loader">
            </div>
            <div class="loading-text">{{ currentText }}</div>
        </div>
    </section>
</ng-template>


<!-- Review Component -->
<ng-template #Review>
    <section>
        <div class="col-12 mb-3" class="review-card">
            <div class="card h-100">
                <div *ngIf="requirements.length == 0" class="card-body d-flex align-items-center justify-content-center">
                    <div>
                        <div class="row h-100">
                            <div class="empty text-center" id="Generate_Requirement_Center_Text">
                                <img class="empty-img" src="assets/images/new-icons/empty.svg" />
                                <span class="empty-description">Review requirements and derive functional test cases</span>
                            </div>
                        </div>
                    </div>

                </div>
                <div *ngIf="requirements.length > 0"
                    class=" comparison-result-container comparison-result-header sticky-top"
                    id="Compare_Requirements_Comparison_Result">
                    <h2 class="head-table">Result</h2>
                </div>

                <div class="table-container" *ngIf="requirements.length > 0">
                    <table id="comparisonTable">
                        <thead>
                            <tr>
                                <th class="req-id">REQ-ID</th>
                                <th class="req-cont">REQUIREMENT</th>
                                <th class="req-cont">VERIFICATION CRITERIA</th>
                                <th>REFINED REQUIREMENTS
                                    <button (click)="toggleRequirementEdit()">
                                    <mat-icon style="padding-top: 0px;">edit</mat-icon>Edit
                                  </button></th>
                                <th>REFINED VERIFICATION CRITERIA<button (click)="toggleVcEdit()">
                                    <mat-icon style="padding-top: 0px;">edit</mat-icon>Edit
                                  </button></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let r of requirements; let i = index" [ngStyle]="{'background-color': getRowColor(r.Refined_Requirement)}">
                                <td class="req-id">{{ r.RQ_ID }}</td>
                                <td class="req-cont">{{ r.Requirement }}</td>
                                <td class="req-cont">{{ r.Verification_Criteria }}</td>
                                <td>
                                    <textarea *ngIf="isRequirementEditable" [(ngModel)]="r.Refined_Requirement" class="editable-field"></textarea>
                                    <span *ngIf="!isRequirementEditable">{{ r.Refined_Requirement }}</span>
                                </td>
                                <td>
                                    <textarea *ngIf="isVcEditable" [(ngModel)]="r.Refined_Verification_Criteria" class="editable-field"></textarea>
                                    <span *ngIf="!isVcEditable">{{ r.Refined_Verification_Criteria }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="output-footer download-report-container download-report-footer sticky-bottom"
                    *ngIf="requirements.length > 0">
                    <button mat-raised-button class="change-btn" id="Compare_Requirements_Export_To_CSV_Button"
                        (click)="exportToCsv(requirements)">
                        <span class="change-text">Export to CSV</span>
                    </button>
                    <!-- <button mat-raised-button class="change-btn"
                        id="Compare_Requirements_Export_To_PDF_Button" (click)="exportToPdf(testcaseData)">
                        <span class="change-text">Export to PDF</span>
                    </button> -->
                    <button mat-raised-button class="change-btn" id="Proceed to next move">
                        <span class="change-text" (click)="proceedTostep3()">Generate TC</span>
                    </button>
                    <button mat-raised-button class="change-btn" id="Proceed to next move">
                        <span class="change-text" (click)="onClearScreen()">Clear</span>
                    </button>
                </div>

            </div>
        </div>

        <div *ngIf="isLoading" class="loader-container">
            <div class="ring-loader">
            </div>
            <div class="loading-text">{{ currentText }}</div>
        </div>
    </section>
</ng-template>

<!-- TestCase Component -->
<ng-template #TestCase>
    <section>
        <div class="table-container merged-table" *ngIf="testcaseData.length > 0">
            <table id="mergedTable">
                <thead>
                    <tr>
                        <th class="req-id">REQ-ID</th>
                        <th>REQUIREMENT</th>
                        <th>VERIFICATION CRITERIA</th>
                        <th *ngIf="hasRefinedData('Refined_Requirement') && !isSkipped">REFINED REQUIREMENTS</th>
                        <th *ngIf="hasRefinedData('Refined_Verification_Criteria') && !isSkipped">REFINED VERIFICATION CRITERIA</th>
                        <th>TEST CASE ID</th>
                        <th class="test-case-name">TEST CASE NAME</th>
                        <th>TEST CASE DESCRIPTION</th>
                        <th>PRE-CONDITION</th>
                        <th>ACTION</th>
                        <th>POST-CONDITION</th>
                        <th>PASS/FAIL CRITERIA</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let row of testcaseData; let i = index">
                        <td *ngIf="isFirstInstance(testcaseData,i, 'RQ_ID')"
                            [attr.rowspan]="getRowSpan(testcaseData, i, 'RQ_ID')" class="req-id">{{ row.RQ_ID }}</td>
                        <td *ngIf="isFirstInstance(testcaseData,i, 'Requirement')"
                            [attr.rowspan]="getRowSpan(testcaseData, i, 'Requirement')">
                            {{ row.Requirement }}
                        </td>
                        <td *ngIf="isFirstInstance(testcaseData,i, 'Verification_Criteria')"
                            [attr.rowspan]="getRowSpan(testcaseData, i, 'Verification_Criteria')">
                            {{ row.Verification_Criteria }}
                        </td>
                        <td *ngIf="!isSkipped && isFirstInstance(testcaseData,i, 'Refined_Requirement')"
                            [attr.rowspan]="getRowSpan(testcaseData, i, 'Refined_Requirement')">{{ 
                            row.Refined_Requirement }}</td>
                        <td *ngIf="!isSkipped && isFirstInstance(testcaseData,i, 'Refined_Verification_Criteria')"
                            [attr.rowspan]="getRowSpan(testcaseData, i, 'Refined_Verification_Criteria')">
                            {{ row.Refined_Verification_Criteria }}</td>
                        <td>{{ row.Test_Case_ID }}</td>
                        <td class="test-case-name">{{ row.Test_Case_Name }}</td>
                        <td>{{ row.Test_Case_Description }}</td>
                        <td>{{ row.Pre_Condition }}</td>
                        <td>{{ formatActionField(row.Action) }}</td>
                        <td>{{ row.Post_Condition }}</td>
                        <td>{{ row.Pass_Fail_Criteria }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="output-footer download-report-container download-report-footer sticky-bottom"
            *ngIf="testcaseData.length > 0">
            <button mat-raised-button class="change-btn" id="Compare_Requirements_Export_To_CSV_Button"
            (click)="exportToCsv(testcaseData)">
                <span class="change-text">Export to CSV</span>
            </button>
            <button mat-raised-button class="change-btn" id="Proceed to next move">
                <span class="change-text" (click)="onClearScreen()">Clear</span>
            </button>
            <!-- <button mat-raised-button class="change-btn"
                        id="Compare_Requirements_Export_To_PDF_Button" (click)="exportToPdf(testcaseData)">
                        <span class="change-text">Export to PDF</span>
                    </button> -->
        </div>

        <div *ngIf="isLoading" class="loader-container">
            <div class="ring-loader">
            </div>
            <div class="loading-text">{{ currentText }}</div>
        </div>
    </section>
</ng-template>