import { ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UploadComponent } from '../../shared/upload/upload.component';
import { UserService } from 'src/app/core/services/user/user.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';

@Component({
  selector: 'app-summarize-qa',
  templateUrl: './summarize-qa.component.html',
  styleUrls: ['./summarize-qa.component.scss']
})
export class SummarizeQaComponent {
  extractedData: string = '';
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  dataSource: any = null;
  newMessage: any = '';
  chatInput: any;
  chatloading : boolean = false;
  onDisable : boolean = false ;
  @ViewChild('chatListContainer', { static: false }) chatListContainer: ElementRef;
  summarizeData:any;
  chatMessages:any = [];
  industry:any;
  constructor(private dialog: MatDialog, private apiService: ApiService, private toastr: ToastrService,private uService : UserService,
    private cdr: ChangeDetectorRef,private encryptionService:EncryptService) {
  }

  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
      });
  }

  ngAfterViewChecked() {
    this.scrollToBottom();
  }



  openPopup() {
    const uploadConfig: IUploadConfig = {
      accept: '.txt,.pdf,.docs,.docx',
      isMultiple : false
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.data = {uploadConfig}
    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(res => {
      if (res) {
        this.onGettext(res);
      }
    })
  }

  onGettext(formdata) {
    this.texts = ["File transfer underway", "Extracting data", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    this.apiService.apiRequestPost('entity_service/upload_file', '', formdata).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.extractedData = parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.toastr.clear();
        this.toastr.success('Success !', 'Data uploaded successfully.');

      }
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        // this.toastr.clear();
        // this.toastr.error('Error data upload Failed.');
      }
    );

  }

  // scrollToBottom() {
  //   if (this.chatListContainer != undefined) {
  //     const element = this.chatListContainer?.nativeElement;
  //     setTimeout(() => {
  //       const maxScroll = this.chatListContainer?.nativeElement.scrollHeight;
  //       this.chatListContainer?.nativeElement.scrollTo({
  //         top: maxScroll,
  //         behavior: 'smooth'
  //       });
  //     }, 1000);
  //   }
  // }

  scrollToBottom() {
    if (this.chatListContainer && this.chatListContainer.nativeElement) {
      this.chatListContainer.nativeElement.scrollTop = this.chatListContainer.nativeElement.scrollHeight;
    }
  }

  onKeyDown(event: KeyboardEvent): void {
    if (event.shiftKey && event.key === 'Enter') {
      event.preventDefault();
      this.chatInput += '\n';
      setTimeout(() => {
        this.chatInput = this.chatInput;
      });
    }
  }


  onChat() {
    const message = this.chatInput 
    if(message.length>0){
      const dataset = {
        question :this.encryptionService.encrypt(this.chatInput),
        context : this.encryptionService.encrypt(this.extractedData),
        industry_id: this.industry
      }
      this.chatloading = true;
      this.onDisable = true;
      this.scrollToBottom();
      if (message != '' && message != null) {
        this.chatMessages.push({
          message: message,
          sender: 1
        });
        this.scrollToBottom(); 
      }
      this.chatInput = "";
      this.apiService.apiRequestPost('ai_services/chatbot',null, dataset).subscribe((data: any) => {
        if (data) {
          const result = this.encryptionService.decrypt(data.answer);
          const parsedData = JSON.parse(result);
          this.newMessage = parsedData;
          this.chatMessages.push({
            message: this.encryptionService.decrypt(data.answer),
            sender: 0
          });
          this.scrollToBottom();
          this.chatloading = false;
          this.onDisable = false;
        }
      },
        err => {
          this.chatloading = false;
          this.onDisable = false;
          this.toastr.clear();
          this.toastr.error( 'Error Please Try Again.');
        }
      );
    }
   
  }

  onSummarize() {
    this.texts = ["Reviewing data", "Collecting Information", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.dataSource = null;
    this.chatInput = "";
    this.startTextAnimation();
    let set = {
      'industry_id': this.industry,
      'text': this.encryptionService.encrypt(this.extractedData)
    }
    this.apiService.apiRequestPost('ai_services/summarize_requirement', null, set).subscribe((data: any) => {
      if (data) {
        this.chatMessages = [];
        this. newMessage = '';
        this.chatMessages = [
          {
            message: `You can start any conversation`,
            sender: 0
          }
          
        ]
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.dataSource = parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.cdr.detectChanges();
      }
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        // this.toastr.clear();
        // this.toastr.error('Error data upload Failed.');
      }
    );
  }


  onClear() {
    this.extractedData = '';
    this.dataSource = null;
  }


  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0; 
      this.currentText = this.texts[this.index];
    }
  }


  copyText() {
    const summary =  this.dataSource;
    const textArea = document.createElement('textarea');
    textArea.value = summary.trim();
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.toastr.clear();
    this.toastr.success('Successfully Copied Summary.');
  }
  


}
