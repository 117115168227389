<div class="row p-3">
  <div *ngFor="let section of sections" class="col-md-4">
    <div class="card">
      <div class="sticky-header">{{ section.title }}</div>
      <div class="scrollable-list">
        <div *ngFor="let item of section.items" class="list-item">
          <div class="card-2">
            <div class="item-header">{{ item.header }}</div>
            <div class="item-content">{{ item.content }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
