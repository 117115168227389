<div class="global-container">
    <div class="input-container">
        <div class="input-content">
            <div class="header">
                <div class="input-heading m-2 mt-0">Generate Requirement</div>
                <div class="icon-btn-change" (click)="toggleLanguage()">
                    <ng-container *ngIf="isEnglish">
                        <img class="btn-size" src="assets/images/EN.svg" alt="English" />
                    </ng-container>
                    <ng-container *ngIf="!isEnglish">
                        <img class="btn-size" src="assets/images/DE.svg" alt="German" />
                    </ng-container>
                </div>
            </div>
            <form [formGroup]="form">
                <div class="">
                    <textarea id="Requirement_Text_Generate_Requirements" formControlName="reqInput"
                        class="input-textarea" [class.small-textarea]="form.get('userStory').value === 'System Diagram'"
                        autocomplete="off"
                        [placeholder]="form.get('userStory').value === 'System Diagram' ? 'Enter the system name (e.g. vehicle lane keep assist system)' : 'Type your requirements'"></textarea>
                </div>
                <span *ngIf="form.get('userStory').value == 'System Diagram' &&!messageHide &&!isHistoryClicked"
                    class="file-message">Please enter
                    the system name in the text box and upload the system diagram</span>
                <div class="requirement-type">
                    <label class="req-label">Requirement type</label>
                    <div class="toggle-buttons">
                        <mat-chip-listbox formControlName="userStory" aria-label="Fish selection"
                            (selectionChange)="setBriefOrDetailedSelected()">
                            <mat-chip-option value="Brief" id="Brief"
                                [disabled]="isBriefOrDetailedSelected||form.get('userStory').value === 'System Diagram'">Brief</mat-chip-option>
                            <mat-chip-option value="Detailed" id="Detailed"
                                [disabled]="isBriefOrDetailedSelected||form.get('userStory').value === 'System Diagram'">Detailed</mat-chip-option>
                            <mat-chip-option value="System Diagram"
                                [disabled]="generateClicked || isDiagramDisabled || isBriefOrDetailedSelected"
                                id="System Diagram">System Diagram</mat-chip-option>
                        </mat-chip-listbox>
                    </div>
                </div>
                <div class="pt-4" *ngIf="form.get('userStory').value !== 'System Diagram'">
                    <mat-checkbox formControlName="includeVerify" id="Include_Verfication_Criteria"
                        class="example-margin">Include Verification Criteria</mat-checkbox>
                </div>
            </form>
            <div class="btn-group" *ngIf="form.get('userStory').value !== 'System Diagram'">
                <button mat-raised-button class="generate-btn" id="Requirement_Button_Generate_Requirement"
                    [ngClass]="{'w-70a': form.get('reqInput').value}" [disabled]="!form.valid" (click)="onGenerate()">
                    <span class="gen-text"> Generate</span>
                </button>
                <button mat-raised-button class="clear-btn" id="Generate_Requirement_Clear_Button" (click)="onClear()"
                    *ngIf="form.get('reqInput').value">
                    <span class="clear-text"> Clear</span>
                </button>
            </div>

            <div class="pt-4" *ngIf="form.get('userStory').value == 'System Diagram'">
                <button mat-raised-button [disabled]="isHistoryClicked" class="upload-btn"
                    id="Upload_File_Review_Requirements" (click)="openPopup()">
                    <span class="upload-text">Upload System Diagram</span>
                    <div class="img-div">
                        <img class="upload-img " src="assets/images/new-icons/upload.svg" />
                    </div>
                </button>
                <div class="uplod-diagram-file pt-2">{{ uploadedFileName }}</div>
            </div>

            <div class="new-btn-group pt-2" *ngIf="form.get('userStory').value == 'System Diagram'">
                <button mat-raised-button class="new-generate-btn" id="Requirement_Button_Generate_Requirement"
                    [ngClass]="{'w-50a': form.get('reqInput').value}" [disabled]="isHistoryClicked || !fileUploaded"
                    (click)="onGenerate()">
                    <span class="gen-text"> Generate</span>
                </button>
                <button mat-raised-button class="new-clear-btn" id="Generate_Requirement_Clear_Button"
                    (click)="onClear()" *ngIf="form.get('reqInput').value">
                    <span class="clear-text"> Clear</span>
                </button>
            </div>

        </div>
    </div>

    <div class="output-container">
        <div class="top-icon" (click)="toggleSideWindow()">
            <img src="assets/images/history_icon.svg" alt="Descriptive Text" id="History_Button"
                class="top-right-image">
        </div>
        <div class="empty" id="Generate_Requirement_Center_Text" *ngIf="data.length == 0 && !isLoading">
            <img class="empty-image" src="assets/images/new-icons/empty.svg" />
            <span class="empty-description"> Convert natural language inputs into formal<br> requirements
                specifications</span>
        </div>
        <div class="output-body">
            <!-- For Brief and Detailed requirements -->
            <div *ngIf="form.get('userStory').value !== 'System Diagram'">
                <div class="data-container" *ngFor="let item of data; let index = index">
                    <div class="data-item" [id]="'Generate_Requirements_Title-' + (index+1)">
                        <div class="d-title-row">
                            <div class="d-title">{{ item.name }}</div>
                        </div>
                        <div class="d-desc-row">
                            <div class="d-desc">{{ item.value }}</div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- For System Diagram requirements -->
            <div *ngIf="form.get('userStory').value === 'System Diagram'">
                <div class="system-data-container" *ngFor="let functionalReq of data">
                    <div class="data-item">
                        <div class="d-title-row">
                            <div class="d-title">{{ functionalReq.name }}</div>
                        </div>
                        <div class="d-desc-row">
                            <div class="d-desc">{{ functionalReq.description }}</div>
                        </div>
                        <div class="technical-req-container mt-2"
                            *ngFor="let techReq of functionalReq.technical_requirements">
                            <div class="technical-req-item">
                                <div class="d-title-row">
                                    <div class="d-title">{{ techReq.name }}</div>
                                </div>
                                <div class="d-desc-row">
                                    <div class="d-desc">{{ techReq.description }}</div>
                                </div>
                                <div class="d-allocation-row">
                                    <div class="d-allocation"><b>Allocation:</b> {{ techReq.allocation }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="output-footer" *ngIf="data.length > 0">
            <button mat-raised-button id="Generate_Requirement_Copy_Button" class="copy-btn" (click)="copyText()">
                <span class="copy-text">Copy</span>
            </button>
            <button mat-raised-button id="Generate_Requirement_Copy_Button" class="copy-btn"
                (click)="exportReqIFFormat()">
                <span class="copy-text">Export as ReqIF</span>
            </button>
        </div>
    </div>
</div>

<div class="side-window" [ngClass]="{'open': isSideWindowOpen}">
    <button class="close-icon" id="History_Close_Button" (click)="toggleSideWindow()">
        &times;
    </button>
    <div class="sidebar-header">
        <img src="assets/images/history_icon.svg" alt="Your Image Alt Text" class="sidebar-image">
        <div class="sidebar-text">History</div>
    </div>
    <div class="side-body-1">
        <div class="top mb-2">
            <ng-container *ngFor="let dateGroup of groupedTopics">
                <div class="date-header">{{ dateGroup.date | isTodayOrTomorrow }}</div>
                <ng-container *ngFor="let topic of dateGroup.topics">
                    <div class="text-ellipsis timestamp-container" style="cursor: poine;">
                        <div style="cursor: pointer;" (click)="onLoadHistory(topic.topic_id)">
                            <span class="timestamp">
                                {{ topic.timestamp | date:'HH:mm' }}&nbsp;&nbsp;
                            </span>
                            <span class="l-border">
                            </span>
                            <span class="topic-question" [matTooltip]="topic.question">
                                {{ topic.question }}
                            </span>
                        </div>
                    </div>
                    <hr>
                </ng-container>
            </ng-container>
        </div>
    </div>
    <hr>
</div>

<div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader">
    </div>
    <div class="loading-text">{{ currentText }}</div>
</div>