import { ThemeService } from './../../core/services/theme.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { ApiService } from 'src/app/core/services/api/api.service';
import { AuthService } from 'src/app/core/services/auth/auth.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { DASHBOARDURL } from 'src/app/core/utils/enum/enum';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  form: FormGroup;
  userName: any;
  currentRoute: string = '';
  heading: any;
  industryOptions: any;
  selectedindustry: any;
  isLoading: boolean = false;
  sideNavBar: boolean;
  sidenav: boolean;
  itemValue: number;
  isIndustryEnabled: boolean = false;
  isTeraTest: boolean = false;
  currentDashboardUrl: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private uService: UserService,
    private authservice: AuthService,
    private apiservice: ApiService,
    private themeService: ThemeService,
    private encryptionService: EncryptService
  ) {
    this.form = new FormGroup({
      industry: new FormControl({ value: '', disabled: true })
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const currentUrl = this.router.url;
        this.updateIndustryDropdownState(currentUrl);
        this.handleNavigation(currentUrl);
      }
    });

    this.themeService.setTheme('inno');
    this.onGetIndustry();
  }

  ngOnInit() {
    const currentUser: any = window.sessionStorage.getItem('currentUser');
    const user = JSON.parse(currentUser)
    this.userName = user.user_name;
    this.currentDashboardUrl = DASHBOARDURL[user.user_profile]
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.currentRoute = this.activatedRoute.snapshot.firstChild?.routeConfig?.path || '';
      });
    
    this.updateIndustryDropdownState(this.router.url);
  }

  updateIndustryDropdownState(url: string) {
    this.isIndustryEnabled = url === '/features/reqai-dashboard' || url === '/features/teratestai-dashboard';
    this.isTeraTest = url === '/features/teratestai-dashboard' || url.includes('teratest-ai');
    if (this.isIndustryEnabled) {
      this.form.get('industry').enable();
    } else {
      this.form.get('industry').disable();
    }
  }

  onGetIndustry() {
    this.isLoading = true;
    this.apiservice.apiRequestGet('entity_service/industry_list').subscribe(
      data => {
        const result = this.encryptionService.decrypt(data);
        const parsedData = JSON.parse(result);
        this.industryOptions = parsedData?.industry_list || [];
        this.selectedindustry = parsedData?.default_industry.industry_id;
        this.form.patchValue({ industry: this.selectedindustry });
        this.uService.setIndustry(this.selectedindustry);
        this.isLoading = false;
        this.updateIndustryDropdownState(this.router.url);
      },
      error => {
        console.error('Failed to fetch industries', error);
        this.isLoading = false;
      }
    );
  }

  onSelectIndustry(value: any) {
    this.selectedindustry = value.value;
    this.apiservice.apiRequestPost('entity_service/set_industry', `?industry_id=${this.selectedindustry}`, null).subscribe(
      (data: any) => {
        if (data) {
          this.form.patchValue({ industry: this.selectedindustry });
          this.onGetIndustry();
        }
      },
      err => {
        console.error('Error setting industry', err);
      }
    );
  }

  handleNavigation(url: string) {
    switch (true) {
      case url.includes('/features/gen-req'):
        this.heading = 'Generate Requirement';
        break;
      case url.includes('/features/rev-req'):
        this.heading = 'Review Requirements';
        break;
      case url.includes('/features/class-req'):
        this.heading = 'Classify Requirements';
        break;
      case url.includes('/features/group-req'):
        this.heading = 'Group Requirements';
        break;
      default:
        this.heading = '';
        break;
    }
  }

  logout() {
    this.authservice.logout().subscribe((data: any) => {
      if (data) {
        sessionStorage.clear();
        this.authservice.currentUserSub(null);
        this.router.navigate(['/']);
      }
    });
  }

  changeTheme(theme: any) {
    this.themeService.setTheme(theme);
    this.onClose();
  }

  onToggle(value: boolean, item?: any) {
    this.itemValue = item;
    this.sidenav = value;
    this.sideNavBar = value;
  }

  onClose() {
    this.sidenav = false;
    this.sideNavBar = false;
  }
}
