import { ChangeDetectorRef, Component, ElementRef, HostListener, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import jsPDF from 'jspdf';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { environment } from 'src/environment/environment';
import { manropeFontBase64 } from '../manrope-font';
import * as XLSX from 'xlsx';
import { MatStepper } from '@angular/material/stepper';
@Component({
  selector: 'app-teratest-generate-testcase',
  templateUrl: './teratest-generate-testcase.component.html',
  styleUrls: ['./teratest-generate-testcase.component.scss']
})
export class TeratestGenerateTestcaseComponent {


  toppings = new FormControl([]);
  toppingList: any[] = [];
  selectedFilesVisible = false;
  fileNamesDisplay: string[] = [];
  industry: any;
  selectedFile: any;
  formUploadData: any;
  uploadFilesName: any;
  requirements = [];
  texts: string[] = [];
  isLoading: boolean = false;
  isRequirementEditable: boolean = false;
  isVcEditable: boolean = false;
  isSkipped: boolean = false;
  intervalId: any;
  index: number = 0;
  extractedData: string = '';
  currentText: any;
  secretKey: string = environment.sKey;
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('stepper') stepper: MatStepper;
  testcaseData = [];
  constructor(private sanitizer: DomSanitizer,
    private apiService: ApiService,
    private uService: UserService,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private encryptionService: EncryptService,
    private cdr: ChangeDetectorRef) { }


  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
    });
    this.onGetReferenceFile();
  }

  getSanitizedHtml(html: string): string {
    if (html.includes('<u>')) {
      html = html.replace(/<u>/g, '<span style="color: red;">').replace(/<\/u>/g, '</span>');
    }
    if (html.includes('<strong>')) {
      html = html.replace(/<strong>/g, '<span style="color: red; font-weight: bold;">').replace(/<\/strong>/g, '</span>');
    }
    if (html.includes('<ins>')) {
      html = html.replace(/<ins>/g, '<span style="color: red;">').replace(/<\/ins>/g, '</span>');
    }
    return html;
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.selectedFile = input.files[0]; // Store the first selected file
      this.fileNamesDisplay = Array.from(input.files).map(file => file.name);
      this.selectedFilesVisible = true;
    } else {
      this.fileNamesDisplay = [];
      this.selectedFilesVisible = false;
      this.selectedFile = null; // Clear the stored file if no files are selected
    }
  }

  onClear() {
    this.toppings.reset();
    this.fileNamesDisplay = [];
    this.requirements = [];
    this.selectedFile = null;
    this.selectedFilesVisible = false;
    this.fileInput.nativeElement.value = '';
  }

  onClearScreen(){
    this.toppings.reset();
    this.fileNamesDisplay = [];
    this.requirements = [];
    this.testcaseData = [];
    this.selectedFile = null;
    this.selectedFilesVisible = false;
    this.fileInput.nativeElement.value = '';
    this.stepper.reset();
  }

  onGetReferenceFile() {
    this.apiService.apiRequestGet('entity_service/reference_list').subscribe(data => {
      const result = this.encryptionService.decrypt(data.output);
      const parsedData = JSON.parse(result);
      this.toppingList = parsedData;
    },
      err => {
        this.toastr.error('Error: Unable to load reference files data.');
      }
    );
  }

  onSkipReviewChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    this.isSkipped = input.checked;
  }

  onCompare() {
    this.requirements = [];
    this.texts = ["Please wait"];
    // const toppingsToString = this.toppings.value.join(', ');
    let formData = new FormData();
    if (this.selectedFile) {
      formData.append('file', this.selectedFile, this.selectedFile.name);
    }
    // const queryString = `${encodeURIComponent(toppingsToString)}`;
    this.isLoading = true;
    this.startTextAnimation();
    this.apiService.apiRequestPost('teratest/review_refined_requirements', null, formData).subscribe((data: any) => {
      if (data) {
        this.onReviewData();
        const result = this.encryptionService.decrypt(data);
        const parsedData = JSON.parse(result);
        this.requirements = parsedData.data;
        this.requirements.forEach((requirement) => {
          requirement.Refined_Requirement = requirement.Refined_Requirement.replace(/\\n/g, '\n');
          requirement.Refined_Verification_Criteria = requirement.Refined_Verification_Criteria.replace(/\\n/g, '\n');
        });
        console.log(this.requirements)
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.cdr.detectChanges();
      }
    },
      err => {
        this.toastr.clear();
        clearInterval(this.intervalId);
        this.toastr.error('Error: Data upload failed.');
        this.isLoading = false;
      }
    );
  }

  underDevelopment(){
    this.toastr.info('Feature under development!');
  }

  onReviewData(){
    this.isLoading = true;
    this.startTextAnimation();
    this.stepper.next();
  }

  submitEditedData() {
    let editedRows = this.requirements.map(r => ({
      RQ_ID: r.RQ_ID,
      Requirement: r.Requirement,
      Verification_Criteria: r.Verification_Criteria,
      Refined_Requirement: r.Refined_Requirement,
      Refined_Verification_Criteria: r.Refined_Verification_Criteria
    }));

    console.log(editedRows);

    this.sendRequirement(editedRows);
  }
  sendRequirement(data1) {
    let data = { data: [...data1] };
    let queryParam = new FormData();
    queryParam.append('text', this.encryptionService.encrypt(JSON.stringify(data)));
    console.log(queryParam)
    this.apiService.apiRequestPost('teratest/refined_generate_testcase', null, queryParam).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.testcaseData = parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.cdr.detectChanges();
      }
    },
      err => {
        this.toastr.clear();
        clearInterval(this.intervalId);
        this.toastr.error(err);
        this.isLoading = false;
      }
    );
  }
  isFirstInstance(tableData: any[], index: number, column: string): boolean {
    if (index === 0) {
      return true;
    }
    return tableData[index][column] !== tableData[index - 1][column];
  }
  getRowSpan(tableData: any[], index: number, column: string): number {
    const value = tableData[index][column];
    let count = 1;
    for (let i = index + 1; i < tableData.length; i++) {
        if (tableData[i][column] === value) {
            count++;
        } else {
            break;
        }
    }
    return count;
}

  proceedTostep3() {
    this.isLoading = true;
    this.submitEditedData();
    this.stepper.next();
  }

  onSkipReview(){
    this.isSkipped ? this.stepper.selectedIndex = 2 : this.stepper.selectedIndex = 1;
    let formData = new FormData();
    if (this.selectedFile) {
      formData.append('file', this.selectedFile, this.selectedFile.name);
    }
    this.isLoading = true;
    this.startTextAnimation();
    this.apiService.apiRequestPost('teratest/refined_generate_testcase', null, formData).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.testcaseData = parsedData;        
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.cdr.detectChanges();
      }
    },
      err => {
        this.toastr.clear();
        clearInterval(this.intervalId);
        this.toastr.error(err);
        this.isLoading = false;
      }
    );
  }

  hasRefinedData(field: string): boolean {
    return this.testcaseData.some(row => !!row[field]);
}

  highlightWords(text: string): string {
    text = text.replace(/style=['"]color:red['"]/g, 'class="red-text"');
    text = text.replace(/\n/g, '<br>');
    text = text.replace(/<\/del>/g, '</div>');
    text = text.replace(/<del>/g, '<div class="change">');
    return text;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent): void {
    const clickedElement = event.target as HTMLElement;

    if (!clickedElement.closest('.editable-field') && 
        !clickedElement.closest('button')) {
      this.isRequirementEditable = false;
      this.isVcEditable = false;
    }
  }

  toggleRequirementEdit() {
    this.isRequirementEditable = true;
  }

  toggleVcEdit() {
    this.isVcEditable = true;
  }

  getRowColor(matchScore: number): string {
    if (matchScore === 100) {
      return '#d9ead3'; // Green
    } else if (matchScore >= 80 && matchScore < 100) {
      return '#fff2cc'; // Yellow
    } else if (matchScore >= 0 && matchScore < 80) {
      return '#FFFFFF'; // White
    } else {
      return '';
    }
  }
  get joinedFileNames(): string {
    return this.fileNamesDisplay.join(', ');
  }

  formatActionField(action: any): string {
    if (typeof action === 'string') {
      action = action.replace(/^,/, '');
    
      action = action.replace(/, step:/g, '\nstep:');
    
      return action;
    } else if (Array.isArray(action)) {
      return action.join('\n');
    } else if (action && typeof action === 'object') {
      return JSON.stringify(action);
    } else {
      return '';
    }
  }

  exportToCsv(exportData): void {
    let csvData = this.convertToCsv(exportData);
    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    let link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'Test_Specification.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private convertToCsv(data: any[]): string {
    const replacer = (key: any, value: any) => (value === null ? '' : value);
    // Determine if 'Refined_Requirement' and 'Refined_Verification_Criteria' columns should be included
    const includeRefinedRequirement = data.some(row => row.Refined_Requirement && row.Refined_Requirement.trim() !== '');
    const includeRefinedVerificationCriteria = data.some(row => row.Refined_Verification_Criteria && row.Refined_Verification_Criteria.trim() !== '');
  
    // Construct the header array based on the conditions
    const header = [
      'RQ_ID',
      'Requirement',
      'Verification_Criteria',
      ...(includeRefinedRequirement ? ['Refined_Requirement'] : []),
      ...(includeRefinedVerificationCriteria ? ['Refined_Verification_Criteria'] : []),
      'Test_Case_ID',
      'Test_Case_Name',
      'Test_Case_Description',
      'Pre_Condition',
      'Action',
      'Post_Condition',
      'Pass_Fail_Criteria'
    ];
  
    // Function to strip HTML tags
    const sanitizeContent = (html: string, fieldName: string) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      let textContent = doc.body.textContent || "";

      textContent = textContent.replace(/[\n\r]+/g, ' ').replace(/['"]/g, '');
      if (fieldName === 'Action') {
        textContent = textContent.replace(/,\s*/g, ' ');  // Replaces commas with a space
      }
      return textContent;
  };

  let csv = [];
  let previousRow = {};

  data.forEach((row, index) => {
      let rowData = header.map((fieldName,columnIndex) => {
          let cellValue = sanitizeContent(row[fieldName], fieldName);
          if (fieldName === 'Test_Case_ID') {
              return JSON.stringify(cellValue, replacer);
          }

          if (columnIndex < 5) { 
          if (index > 0 && previousRow[fieldName] === row[fieldName]) {
              return '';
          } else {
              return JSON.stringify(cellValue, replacer);
          }
        }
        else {
              return JSON.stringify(cellValue, replacer);
          }
      }).join(',');

      csv.push(rowData);
      previousRow = row;
  });

    csv.unshift(header.join(','));
    return csv.join('\r\n');
  }

  // if need to download as excel then use below code.
  exportToExcel(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('comparisonTable'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Comparison Report');

    XLSX.writeFile(wb, 'Test_Specification.csv.xlsx');
  }

  exportToPdf(ExportData) {
    const data = document.getElementById('comparisonTable');
    if (data) {
      // Ensure the font is loaded and supported by jsPDF
      const fontFamily = 'Manrope'; // Make sure this font is available

      // Apply font family to the content
      data.style.fontFamily = fontFamily;

      // Create PDF instance
      const pdf = new jsPDF('p', 'mm', 'a4');

      // Add the font to jsPDF's virtual file system
      pdf.addFileToVFS('manrope-regular.ttf', manropeFontBase64);

      // Add and set the font for the PDF
      pdf.addFont('manrope-regular.ttf', 'Manrope', 'normal');
      pdf.setFont('Manrope');

      // Define the width for the content in the PDF
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20; // Subtract margins


      // Generate PDF from HTML content
      pdf.html(data, {
        callback: function (pdf) {
          // Restore original font family
          data.style.fontFamily = fontFamily;
          pdf.save('Test_Specification.csv.pdf'); // Save the PDF
        },
        x: 10, // Left margin
        y: 10, // Top margin
        width: pdfWidth, // Set the width for scaling
        windowWidth: data.scrollWidth, // Use the scrollWidth of the content for scaling
      });
    }
  }


  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }
}
