import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-tera-test-ai-dashboard',
  templateUrl: './tera-test-ai-dashboard.component.html',
  styleUrls: ['./tera-test-ai-dashboard.component.scss']
})
export class TeraTestAiDashboardComponent {

  industry: any;
  industryIsEmpty: boolean = true;

  data = [
    { id: 'Testability Review', name: 'Testability Review', logo: "assets/images/Review Requirements_Iconset.svg", route: '../teratest-ai-rev-req', description: 'Review and improve ambiguous, incomplete, incorrect, illogically formatted requirements' },
    { id: 'Generate Test Cases', name: 'Generate Test Cases', logo: "assets/images/functional-testcase.svg", route: '../teratest-ai-functional-testcase', description: 'Derive functional test cases from system requirements' },
    { id: 'Review & Generate Test Cases', name: 'Review & Generate Test Cases', logo: "assets/images/functional-testcase.svg", route: '../teratest-ai-generate-testcase', description: 'Review requirements and derive functional test cases' }
  ];

  constructor(
    private router: Router,
    private uService: UserService,
  ) { }

  ngOnInit() {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
      this.industryIsEmpty = !this.industry || this.industry.length === 0; // Set flag based on industry data
    });
    this.preloadFeatureImages();
  }

  preloadFeatureImages(): void {
    const imageUrls = this.data.map((feature) => feature.logo);
    this.uService.preloadImages(imageUrls);
  }
}
