import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { ApiService } from 'src/app/core/services/api/api.service';

@Component({
  selector: 'app-info-dashboard',
  templateUrl: './info-dashboard.component.html',
  styleUrls: ['./info-dashboard.component.scss']
})
export class InfoDashboardComponent {

  constructor() {
  }

  sections = [
    {
      title: 'GEN AI',
      items: [
        { header: 'CAIP', content: 'Lorem ipsum dolor sit amet' },
        { header: 'Recuruit AI', content: 'Nam vestibulum sodales odio ut pulvinar.' },
        { header: 'Dei Checker', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' },
        { header: 'Diligence GPT', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' },
        { header: 'Requirement Engineering', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' },
        { header: 'Dei Checker', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' },
        { header: 'Code Gen GPT', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' }
      ]
    },
    {
      title: 'AI/ML - Analytics',
      items: [
        { header: 'GAI', content: 'Lorem ipsum dolor sit amet' },
        { header: 'Vehicle Analytics (Teractics.ai)', content: 'Nam vestibulum sodales odio ut pulvinar.' },
        { header: 'Video Analytics (botseye.com)', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' },
        { header: 'Forecasting', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' },
      ]
    },
    {
      title: 'Non AI Products',
      items: [
        { header: 'Field Force Management', content: 'Lorem ipsum dolor sit amet' },
        { header: 'Deductions Management', content: 'Nam vestibulum sodales odio ut pulvinar.' },
        { header: 'NeoTracs', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' },
        { header: 'NeoWings', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' },
        { header: 'NeoTag', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' },
        { header: 'NeoFleet', content: 'consectetur adipiscing elit. Fusce a volutpat mauris' },
      ]
    },
  ];
  

}
