import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environment/environment';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  currentUserSubject: any;
  topicId: any = 0;
  sessionFlag: any;
  loginUrl: string;
  constructor(private http: HttpClient) {
    this.loginUrl = environment.url;
  }
  public get currentUserValue() {
    return this.currentUserSubject.value;
  }
  public currentUserSub(data: any) {
    this.currentUserSubject = data;
  }


  setSessionExpired(data: any) {
    this.sessionFlag = data;
  }

  getSessionExpired() {
    return this.sessionFlag;
  }

  login(username: string, password: string) {
    return this.http.get<any>(this.loginUrl + `auth/signin`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + window.btoa(username + ':' + password)
      })
    });
  }

  resetPassword(email: string) {
    return this.http
      .post<any>(this.loginUrl + `auth/send_otp?email=` + email, '');
  }

  setLocalStorage(user: any) {
    if (user && user.user_details) {
      const x: any = window.sessionStorage.getItem('currentUser');
      sessionStorage.setItem('currentUser', JSON.stringify(user.user_details));
      var curdata: any = window.sessionStorage.getItem('currentUser');
      curdata = curdata ? JSON.parse(curdata) : {};
      curdata['email'] = JSON.parse(x).email;
      curdata['user_name'] = JSON.parse(x).user_name;
      curdata['user_type'] = JSON.parse(x).user_type;
      sessionStorage.setItem('currentUser', JSON.stringify(curdata));
      this.currentUserSubject.next(user);
      return user;
    }
  }

  verifyOtp(user_id: string, otp: string) {
    return this.http.post<any>(
      this.loginUrl +
      `auth/verify_otp?user_id=` +
      user_id +
      '&auth_code=' +
      otp,
      ''
    );
  }

  resetPwd(user_id: string, pwd: string, auth_key: string) {
    return this.http.post<any>(
      this.loginUrl +
      `auth/update_password?user_id=` +
      user_id +
      '&password=' +
      pwd +
      '&auth_key=' +
      auth_key,
      ''
    );
  }
  
  logout() {
    let session_id: any = sessionStorage.getItem('session_id');
    let authorization = sessionStorage.getItem('authorization');
    return this.http.get(this.loginUrl + `auth/sign_out`, {
      headers: new HttpHeaders({
        session_id: session_id,
        authorization: 'Bearer ' + authorization
      })
    });
  }

}
