import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  industry: any;
  industryIsEmpty: boolean = true;

  data = [
    { id: 'Req AI', name: 'Req AI', logo: "assets/images/ReqAI.svg", route: '../reqai-dashboard', description: 'Convert natural language inputs into formal requirements specifications.' },
    { id: 'TeraTest AI', name: 'TeraTest AI', logo: "assets/images/TeraTestAI Logo.png", route: '../teratestai-dashboard', description: 'Review and improve ambiguous, incomplete, incorrect, illogically formatted requirements.' },
  ];

  constructor(
    private router: Router,
    private uService: UserService,
  ) {}

  ngOnInit() {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
      this.industryIsEmpty = !this.industry || this.industry.length === 0; // Set flag based on industry data
    });
    this.preloadFeatureImages();
  }

  preloadFeatureImages(): void {
    const imageUrls = this.data.map((feature) => feature.logo);
    this.uService.preloadImages(imageUrls);
  }

}
