<div class="global-container">
    <div class="input-container">
        <div class="input-content">
            <span class="input-heading m-2 mt-0 text-start">Cybersecurity - TARA  </span>

            <div class="input-heading m-2 asset-heading">
                Asset
                <mat-icon matTooltip="Assets is always only one value." class="info-text">info</mat-icon>
            </div>
            
            <div class="">
                <textarea [(ngModel)]="extractedAssestData" id="tara_asset_details" class="input-textarea" autocomplete="off"
                    placeholder="Enter the asset details"></textarea>
            </div>

            <div class="mt-2 ">
                <span class="input-heading mx-2">Attacks </span>
                <div *ngFor="let textarea of textareas; let i = index">
                    <div class="textarea-container">
                        <div class="textarea-wrapper">
                            <textarea [(ngModel)]="textarea.value" id="textarea_{{i}}" class="input-textarea1" autocomplete="off"
                                placeholder="Enter the attack details"></textarea>
                            <button matTooltip="Remove" mat-mini-fab class="remove-button" (click)="removeTextarea(i)" *ngIf="textareas.length > 1">
                                <img class="empty-img ico" src="assets/images/Delete.svg" />
                            </button>
                        </div>
                    </div>
                </div>
                <span class="add-more mx-2" (click)="addTextarea()" >Add More Attacks + </span>

            
            
            
            <div class="btn-group">
                <button mat-raised-button class="generate-btn" [ngClass]="{'w-70a': extractedAssestData || isAnyFieldFilled()}" id="tara_Generate_Button" [disabled]="!extractedAssestData || !allTextareasFilled()" (click)="onGenerate()">
                    <span class="gen-text"> Generate</span>
                </button>                
                <button mat-raised-button class="clear-btn" id="tara_Clear_Button" (click)="onClear()" *ngIf="isAnyFieldFilled()">
                    <span class="clear-text"> Clear</span>
                </button>                
            </div>

        </div>
    </div>
    </div>
    <div class="output-container" id="tara_Generated_Results">
        <div class="empty" id="tara_Center_Text" *ngIf="dataSource.length == 0 && !isLoading" >
            <img class="empty-img" src="assets/images/new-icons/empty.svg" />
            <span class="empty-description"> Analyse threats and assess risks for the given asset and attack scenarios
            </span>
        </div>
        <div class="output-body"  *ngIf="dataSource.length != 0">
        
            <div class="t-head-container">
                <h2 class="head-table mb-2">Threat Analysis and Risk Assessment (TARA) </h2>
              </div>

            <div class="table-container" >
                <table id="taraTable">
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Assest</th>
                      <th>Attack</th>
                      <th>Threat</th>
                      <th>Consequences</th>
                      <th>Risk</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor=" let item of dataSource">
                      <td>{{item.S_NO}}</td>
                      <td>{{item.Asset}}</td>
                      <td>{{item.Attack}}</td>
                      <td>{{item.Threat}}</td>                      
                      <td>{{item.Consequences}}</td>
                      <td>{{item.Risk | capitalizeFirst}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
        </div>
        <div class="output-footer" *ngIf="dataSource.length != 0">
            <button mat-raised-button class="copy-btn" id="tara_Generated_Results_Export_To_CSV_Button" (click)="exportToCsv()">
                <span class="copy-text">Export to CSV</span>
            </button>
        </div>
    </div>
</div>


<div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader">
    </div>
    <div class="loading-text">{{ currentText }}</div>
</div>