<mat-dialog-content>
  <div class="w-100" fxLayout="row" fxLayoutAlign="end center">
    <span class="upload-texts text-center">Upload</span> 
    <mat-icon (click)="onClose()">close</mat-icon>
  </div>
  

  <div class="upload-files-container">
    <label class="drag-file-area" for="fileInput">
      <!-- <mat-icon class="upload-icon">cloud_upload</mat-icon> -->
      <img src="assets/images/Upload icon.svg" class="upload-icon" />
      <span class="drag-drop-text pt-3">Drag & drop files or <u style="color:#0B3F70;">Browse</u></span>
      <span class="file-types pt-2">Supported formats:{{uploadConfig.accept}}</span>
      <input id="fileInput" class="d-none default-file-input" type="file" #fileInput required [multiple]="uploadConfig?.isMultiple"
      name="fileUpload" [accept]="uploadConfig?.accept" (change)="uploadFileNew($event)" />
    </label>
    <span class="cannot-upload-message" *ngIf="isError">
       Please select a file
      <mat-icon class="error-icon" (click)="onCloseError()">close</mat-icon>
    </span>

    <div class="file-block w-100" *ngFor="let item of files; let index = index">
      <div class="file-info">
        <span class="file-name">{{item.fName}}</span> | <span class="file-size">{{item.fSize}}</span>
        <span class="material-icons remove-file-icon" (click)="removeSelectedFile()">delete</span>
      </div>
    </div>

    <button type="button" class="upload-button" (click)="onUpload()">
      <span class="upload-text">Upload Files</span>
    </button>
  </div>
</mat-dialog-content>






