import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, tap, throwError } from 'rxjs';
import { environment } from 'src/environment/environment';
const BASE_URL = environment.url;
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private http: HttpClient) { }

 

  apiRequestGet(endPoint?: any, queryParam?: any): Observable<any> {
    let session_id: any = sessionStorage.getItem('session_id');
    let authorization: any = sessionStorage.getItem('authorization');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.get<any>(`${url}`, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        session_id: session_id,
        authorization: 'Bearer ' + authorization
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err))
      })
    );
  }

  apiRequestPost(endPoint?:any,queryParam?:any,payload?:any): Observable<any> {
    let session_id:any = sessionStorage.getItem('session_id');
    let authorization:any = sessionStorage.getItem('authorization');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }
    return this.http.post<any>(`${url}`, payload, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        session_id: session_id,
        authorization: 'Bearer ' + authorization
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error(err))
      })
    );
  }

  apiRequestDelete(endPoint?:any,queryParam?:any,payload?:any): Observable<any> {
    let session_id:any = sessionStorage.getItem('session_id');
    let authorization:any = sessionStorage.getItem('authorization');

    let url = BASE_URL + endPoint;
    if (queryParam) {
      url = url + queryParam;
    }

    return this.http.delete<any>(`${url}`, {
      headers: new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        session_id: session_id,
        authorization: 'Bearer ' + authorization
      })
    }).pipe(
      catchError((err) => {
        return throwError(() => new Error())
      })
    );
  }

  convertTextToFormData(text: string): FormData {
    const formData = new FormData();
    formData.append('text', text);
    return formData;
  }

  downloadFile(endPoint: string, payload: any, filename: string) {
    const session_id: any = sessionStorage.getItem('session_id');
    const authorization: any = sessionStorage.getItem('authorization');
    const url = BASE_URL + endPoint;
    const headers = new HttpHeaders({
        'ngrok-skip-browser-warning': 'truppe',
        session_id: session_id,
        authorization: 'Bearer ' + authorization
    });

    return this.http.post(url, payload, { headers: headers, responseType: 'blob' }).pipe(
        tap((blob: Blob) => {
            const a = document.createElement('a');
            const objectUrl = URL.createObjectURL(blob);
            a.href = objectUrl;
            a.download = filename;
            a.click();
            URL.revokeObjectURL(objectUrl);
        })
    );
}

}
