import { ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Renderer2, ViewChild, Output , AfterViewInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadComponent } from '../../shared/upload/upload.component';
import { ChartComponent } from 'ng-apexcharts';
import { overallscore, overAllpercentage } from 'src/app/core/utils/data/chart-data';
import { ApiService } from 'src/app/core/services/api/api.service';
import { ToastrService } from 'ngx-toastr';
import { ReviewAlternativeComponent } from '../../shared/review-alternative/review-alternative.component';
import { IUploadConfig, IalterDetails, Ireplace } from 'src/app/core/utils/interface/shared.interface';
import { ReviewComparisionComponent } from '../../shared/review-comparision/review-comparision.component';
import { UserService } from 'src/app/core/services/user/user.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';

@Component({
  selector: 'app-review-requirements',
  templateUrl: './review-requirements.component.html',
  styleUrls: ['./review-requirements.component.scss']
})
export class ReviewRequirementsComponent implements OnInit {
  groupBy: any = [
    { id: 'quality_review', value: 'Quality Review'  },
    { id: 'testability_review', value: 'Testability Review' },
  ];
  editingProposal: boolean = false;
  editableProposal: string = '';
  selectedValue: string = 'Quality Review'; 
  isGraph : boolean = true;
  extractedData: string = '';
  dataSource: any = null;
  originalDataSource: any;
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  isCompare: boolean = false;
  industry: any;
  storyId: string = '';
  isJiraStory : boolean = false;
  hasModifications: boolean = false;
  @ViewChild('content') content: ElementRef;
  @ViewChild("overallscore") overallscore: ChartComponent;
  @ViewChild("overAllpercentage") overAllpercentage: ChartComponent;
  public overallscoreoptions: Partial<any>;
  public overAllpercentageoptions: Partial<any>;
  scoreHeading = ['Semantics', 'Grammar', 'Plausibility', 'Consistency', 'Context'];

  constructor(private dialog: MatDialog, private apiService: ApiService, private toastr: ToastrService, private renderer: Renderer2,
    private cdr: ChangeDetectorRef, private uService: UserService, private encryptionService:EncryptService) {
    this.overallscoreoptions = overallscore;
    this.overAllpercentageoptions = overAllpercentage;
  }


  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
    });
  }

  onValueChange(event: any) {
    this.selectedValue = event.value;
  }

  getJiraStory(){
    this.isLoading = true;
    const queryParam = `${this.storyId}`;
    this.apiService.apiRequestGet(`jira_service/get_story_description/`, queryParam).subscribe(data => {
      if (data) {
        const result = this.encryptionService.decrypt(data.unparsed_description);
        const parsedData = JSON.parse(result);
        this.extractedData = parsedData;
        this.isJiraStory = true;
        this.isLoading = false;
      }
    }, 
     err => {
      this.isLoading = false;
      // this.toastr.clear();
      // this.toastr.error('Error unable to fetch data.');
    });
  }


  openPopup() {
    const uploadConfig: IUploadConfig = {
      accept: '.txt,.pdf,.docs,.docx',
      isMultiple : false
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.data = {uploadConfig}
    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(res => {
      if (res) {
        this.extractedData = '';
        this.onGettext(res);
      }
    })
  }

  onGettext(formdata) {
    this.texts = ["File transfer underway", "Extracting data", "Please wait"];
    this.isLoading = true;
    this.startTextAnimation();
    this.apiService.apiRequestPost('entity_service/upload_file', '', formdata).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.extractedData = parsedData;
        this.isLoading = false;
        this.toastr.clear();
        this.toastr.success('Success !', 'Data uploaded successfully.');
      }
    },
      err => {
        this.isLoading = false;
        // this.toastr.clear();
        // this.toastr.error('Error data upload Failed.');
      }
    );

  }


  openAlterDetailsPopup(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.enterAnimationDuration = 2;
    dialogConfig.exitAnimationDuration = 700;
    dialogConfig.data = this.onTransForm(data);
    this.dialog.open(ReviewAlternativeComponent, dialogConfig).afterClosed().subscribe(res => {
      this.onGet(res);

    })
  }

  @HostListener('click', ['$event'])
  onClick(event: Event) {
    const target = event.target as HTMLElement;
    if (target && target.classList.contains('alter-content')) {
      const word = target.innerText;
      if (word) {
        const data = this.dataSource?.recommended_modifications?.find((x) => x.modification_point == word);
        if (data && data.alternatives && data.alternatives.length > 0) {
          this.openAlterDetailsPopup(data);
        }
      }
    }
  }


  highlightWords(text: string): string {
    if (this.dataSource?.recommended_modifications && this.dataSource?.recommended_modifications?.length > 0) {
      this.dataSource?.recommended_modifications?.forEach(obj => {
        const escapedModificationPoint = obj.modification_point.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
        const regex = new RegExp(escapedModificationPoint, 'g');
        text = text.replace(regex, `<span class="alter-content ${obj?.impact_level}">${obj?.modification_point}</span>`);
      });
    }
    text = text.replace(/\n/g, '<br>');
    return text;
  }
  toggleEdit(): void {
    this.editableProposal = this.dataSource.info2_value; // Set the initial value to the existing proposal
    this.editingProposal = true;
  }

  saveEdit(): void {
    this.dataSource.info2_value = this.editableProposal; // Save the edited text back to the dataSource
    this.editingProposal = false;
  }

  cancelEdit(): void {
    this.editingProposal = false; 
  }

  onReview() {
    this.texts = ["Reviewing data", "Collecting Information", "Please wait"];
    this.dataSource = null;
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
  
    let set = {
      'industry_id': this.industry,
      'text': this.encryptionService.encrypt(this.extractedData),
      'review_type': this.selectedValue 
    };
    this.apiService.apiRequestPost('ai_services/review_requirements', null, set).subscribe((data: any) => {
      if (data && data.output) {
        if (data.output.error) {
          this.toastr.clear();
          this.toastr.error(data.output.error); 
        } else {
          const result = this.encryptionService.decrypt(data.output);
          const parsedData = JSON.parse(result);
          this.dataSource = parsedData;
          console.log(this.dataSource);
          if(this.selectedValue == "Testability Review" ){
            this.isGraph = false; 
          }else{
            this.isGraph = true;
            let scoreHeading = ['Semantics','Grammar','Plausibility','Consistency','Context'];
            this.originalDataSource = { ...parsedData };
            overallscore.series = [this.dataSource.overall_score];
            overAllpercentage.series = [
              { data: [...this.dataSource.item_ratings] }
            ];
            this.dataSource.scoreHeading = [...scoreHeading];
          }
        }
      } else {
        this.toastr.clear();
        this.toastr.error('No data received from the server.');
      }
      this.isLoading = false;
      clearInterval(this.intervalId);
      this.cdr.detectChanges();
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        // this.toastr.clear();
        // this.toastr.error('Error data upload Failed.');
      }
    );
  }


  onGet(data: any) {
    let set: Ireplace = {
      user_input: this.encryptionService.encrypt(this.dataSource.user_content),
      modifications: this.encryptionService.encrypt(JSON.stringify([
        {
          original_text: data.originalWord,
          suggested_text: data.replaceWord,
        },
      ])),
      recommended_modifications: this.encryptionService.encrypt(JSON.stringify(this.dataSource.recommended_modifications)),
    };

    this.isLoading = true;
    this.apiService.apiRequestPost('entity_service/change-alternatives', '', set).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data);
        const parsedData = JSON.parse(result);
        this.dataSource["user_content"] = parsedData.modified_input;
        this.dataSource["recommended_modifications"] =parsedData.recommended_modifications;
        this.isCompare = true;
        this.isLoading = false;
        this.toastr.clear();
        this.toastr.success('Success !', 'Data uploaded successfully.');
        this.hasModifications = true;
      }
    },
      err => {
        this.toastr.clear();
        this.toastr.error('Error data upload Failed.');
        this.isLoading = false;
      }
    );
  }

  openComparison() {
    let innerHtmlContent = '';
    if (this.content && this.content.nativeElement) {
      innerHtmlContent = this.content.nativeElement.innerHTML;
      if (innerHtmlContent) {
        const tempElement = this.renderer.createElement('div');
        tempElement.innerHTML = innerHtmlContent;
        ['alter-content', 'high', 'medium', 'low'].forEach(className => {
          const elements = tempElement.getElementsByClassName(className);
          for (let i = 0; i < elements.length; i++) {
            this.renderer.removeClass(elements[i], className);
          }
        });
        innerHtmlContent = tempElement.innerHTML;
      }
    }
    if (innerHtmlContent.trim() !== '') {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.height = '90%';
      dialogConfig.width = '90%';
      dialogConfig.hasBackdrop = true;
      dialogConfig.panelClass = 'custom-dialog-class';
      dialogConfig.data = {
        originalDataSource: this.extractedData,
        changedDataSource: innerHtmlContent,
      };
      const dialogRef = this.dialog.open(ReviewComparisionComponent, dialogConfig);
      dialogRef.afterClosed().subscribe(res => {

      });
    } else {
      console.error('No content to display');
    }
  }


  replaceAllWithAlternatives() {
    if (this.dataSource?.recommended_modifications && this.dataSource.recommended_modifications.length > 0) {
      let data = this.dataSource.recommended_modifications.filter(x => x.alternatives.length > 0)
      const modifications = data.map(modification => {
        return {
          original_text:modification.modification_point,
          suggested_text: modification.alternatives ? modification.alternatives[0] : null
        };
      });
      const encryptedModifications = this.encryptionService.encrypt(JSON.stringify(modifications));
      let set: Ireplace = {
        user_input: this.encryptionService.encrypt(this.dataSource.user_content),
        modifications: encryptedModifications,
        recommended_modifications: this.encryptionService.encrypt(JSON.stringify(this.dataSource.recommended_modifications)),
      };

      this.isLoading = true;
      this.apiService.apiRequestPost('entity_service/change-alternatives', '', set).subscribe((data: any) => {
        if (data) {
          const result = this.encryptionService.decrypt(data);
          const parsedData = JSON.parse(result);
          this.dataSource["user_content"] = parsedData.modified_input;
          this.dataSource["recommended_modifications"] = parsedData.recommended_modifications;
          this.isCompare = true;
          this.isLoading = false;
          this.toastr.clear();
          this.toastr.success('Success!', 'Data uploaded successfully.');
          this.hasModifications = true;
        }
      },
        err => {
          this.toastr.clear();
          this.toastr.error('Error: Data upload failed.');
          this.isLoading = false;
        });
    }
  }


  onRevert() {
    if (this.originalDataSource) {
      this.dataSource.user_content = this.originalDataSource.user_content;
      this.dataSource.recommended_modifications = this.originalDataSource.recommended_modifications;
      this.isCompare = false;
      this.toastr.clear();
      this.toastr.success('Success!', 'Data reverted successfully.');
      this.hasModifications = false;
    }
  }


  onTransForm(data: any): any {
    let set = <IalterDetails>{};
    set.modification_point = data?.modification_point,
      set.alternativeWords = data?.alternatives || []
    return set;
  }


  onClear() {
    this.extractedData = '';
    this.dataSource = null;
    this.isJiraStory = false;
    this.storyId =  null;
    this.hasModifications = false;
  }



  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }


  copyText() {
    if (this.isGraph) {
      // If it's the graph view, copy directly from the graph container
      this.copyGraphContent();
    } else {
      // If it's not the graph view, copy structured text content
      this.copyStructuredTextContent();
    }
  }
  
  copyGraphContent() {
    const container = this.content.nativeElement;
    if (!container) {
      console.error('Reviewed userstory is not found.');
      this.toastr.error('Error: No Reviewed userstory to copy.');
      return;
    }
  
    const range = document.createRange();
    range.selectNode(container);
  
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  
    try {
      document.execCommand('copy');
      this.toastr.clear();
      this.toastr.success('Reviewed userstory is copied successfully.');
    } catch (err) {
      this.toastr.error('Error copying Reviewed userstory.');
    } finally {
      selection.removeAllRanges();
    }
  }
  
  copyStructuredTextContent() {
    let contentToCopy = '';
  
    // Assemble the content from data source
    if (this.dataSource && this.dataSource.info_value) {
      contentToCopy += `${this.dataSource.info_key}:\n`;
      this.dataSource.info_value.forEach(item => {
        contentToCopy += `\n${item.key}:\nAmbiguity: ${item.value.Ambiguity}\nSuggestion: ${item.value.Suggestion}\n`;
      });
    }
  
    if (this.dataSource && this.dataSource.info2_value) {
      contentToCopy += `\n${this.dataSource.info2}:\n${this.editingProposal ? this.editableProposal : this.dataSource.info2_value}\n`;
    }
  
    // Use the Clipboard API to copy the structured text
    navigator.clipboard.writeText(contentToCopy).then(() => {
      this.toastr.clear();
      this.toastr.success('Text content copied successfully.');
    }).catch(() => {
      this.toastr.error('Failed to copy text content.');
    });
  }
  
  formatItem(item) {
    if (typeof item === 'object' && item !== null) {
      let formattedItem = '';
      for (const key in item) {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          formattedItem += `${key}: ${item[key]}\n`;
        }
      }
      return formattedItem + '\n';
    } else {
      return `${item}\n\n`;
    }
  }

}


