<div class="global-container">
  <div class="input-container">
    <div class="input-content">
      <span class="input-heading m-2 mt-0"> HARA using HAZOP </span>
      <div class="">
        <textarea [(ngModel)]="extractedData" id="HARA_Using_HAZOP_Enter_Your_Requirements" class="input-textarea"
          autocomplete="off" placeholder="Enter Item Defenition..."></textarea>
      </div>

      <div class="mt-3 mb-2">
        <mat-form-field appearance="outline" class="custom-mat-form-field drop1">
          <mat-label>Guide Words</mat-label>
          <mat-select id="HARA_Using_HAZOP_Drop_Down" [(ngModel)]="selectedGuideWords"
            (selectionChange)="onValueChange($event)" multiple>
            <mat-option *ngFor="let data of guideWords" [value]="data.name">
              {{ data.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="mt-2 ">
        <span class="input-heading mx-2">Enter Operating Scenarios & Modes </span>
        <div *ngFor="let textarea of combinedTextareas; let i = index">
          <div class="textarea-container">
            <div class="textarea-wrapper">
              <textarea [(ngModel)]="textarea.value" id="textarea_{{i}}" class="input-textarea1" autocomplete="off"
                [placeholder]="i % 2 === 0 ? 'Enter Operating Scenario' : 'Enter Operating Mode'"></textarea>
              <button matTooltip="Remove" mat-mini-fab class="remove-button" (click)="removeOperatingScenario(i)"
                *ngIf="i > 1">
                <img class="empty-img ico" src="assets/images/Delete.svg" />
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="add-more mx-2" (click)="addOperatingScenariosAndModes()">Add Operating Scenarios and Modes +</div>


      <div class="btn-group">
        <button mat-raised-button class="generate-btn"
          [ngClass]="{'w-70a': extractedData || selectedGuideWords.length > 0||isAnyFieldFilled()}" id="HARA_Using_HAZOP_Generate_Button"
          [disabled]="!extractedData || selectedGuideWords.length === 0 || !isScenarioAndModesFilled"
          (click)="onGenerate()">
          <span class="gen-text"> Generate</span>
        </button>
        <button mat-raised-button class="clear-btn" id="HARA_Using_HAZOP_Clear_Button" (click)="onClear()"
          *ngIf="isAnyFieldFilled()">
          <span class="clear-text"> Clear</span>
        </button>
      </div>
    </div>
  </div>
  
  <div class="output-container" id="HARA_Using_HAZOP_Generated_Results">
    <div class="empty" id="hazop_Center_Text" *ngIf="dataSource.length == 0 && !isLoading">
      <img class="empty-img" src="assets/images/new-icons/empty.svg" />
      <span class="empty-description"> Derive HARA and safety goals using HAZOP for given functional requirement,<br>
        considering operational scenarios and modes.
      </span>
    </div>
    <div class="output-body" *ngIf="dataSource.length != 0">
      <div class="data-container" *ngFor="let item of dataSource.requirement; let index = index">
        <div class="data-item" [id]="'hazop_Title-' + (index+1)">
          <div class="d-title-row">
            <div class="d-title">{{ item.name }}</div>
          </div>
          <div class="d-desc-row">
            <div class="d-desc">{{ item.value }}</div>
          </div>
        </div>
      </div>

      <div class="t-head-container">
        <h2 class="head-table mb-0">Assessment :</h2>
      </div>

      <div class="table-container">
        <table id="hazopTable">
          <thead>
            <tr>
              <th>Guide Word</th>
              <th>Malfunction</th>
              <th>Operation Scenario</th>
              <th>Operating Mode</th>
              <th>Hazard</th>
              <th>Assumption</th>
              <th>Severity</th>
              <th>Severity Rationale</th>
              <th>Safety Goal</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of dataSource">
              <td>{{ item.Guide_Word }}</td>
              <td>{{ item.Malfunction }}</td>
              <td>{{ item.Operating_Scenario }}</td>
              <td>{{ item.Operating_Mode }}</td>
              <td>{{ item.Hazard }}</td>
              <td>{{ item.Assumption }}</td>
              <td>{{ item.Severity }}</td>
              <td>{{ item.Severity_Rationale }}</td>
              <td>{{ item.Safety_Goal }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="output-footer" *ngIf="dataSource.length != 0">
      <button mat-raised-button class="copy-btn" id="HARA_Using_HAZOP_Generated_Results_Export_To_CSV_Button"
        (click)="exportToCsv()">
        <span class="copy-text">Export to CSV</span>
      </button>
    </div>
  </div>
</div>


<div *ngIf="isLoading" class="loader-container">
  <div class="ring-loader">
  </div>
  <div class="loading-text">{{ currentText }}</div>
</div>