<div class="global-container">
  <div class="input-container">
      <div class="input-content">
          <span class="input-heading m-2 mt-0">Generate System Design</span>
          <div class="">
              <textarea [(ngModel)]="extractedData"  id="Generate_System_Design_Enter_Your_Requirements" class="input-textarea" autocomplete="off"
                  placeholder="Enter your requirements"></textarea>
          </div>

          <div class="mt-3">
            <mat-form-field appearance="outline" class="custom-mat-form-field drop1">
              <mat-label>Modeling Language</mat-label>
              <mat-select id="Generate_System_Design_Drop_Down" (selectionChange)="onValueChange($event)">
                <mat-option class="opt" *ngFor="let data of groupBy" [value]="data.id" [id]="data.testid">{{ data.name }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          
          <div class="btn-group">
              <button mat-raised-button id="Upload_File_Generate_System_Design" class="upload-btn" (click)="openPopup()" *ngIf="!extractedData">
                  <img class="upload-img" src="assets/images/new-icons/upload.svg" />
              </button>
              <button mat-raised-button class="generate-btn" id="Generate_System_Design_Generate_Button"  [disabled]="selectedValue != 1 || !extractedData" (click)="onGenerate()">
                  <span class="gen-text"> Generate</span>
              </button>
              <button mat-raised-button class="clear-btn" id="Generate_System_Design_Clear_Button" (click)="onClear()" *ngIf="extractedData">
                  <span class="clear-text"> Clear</span>
              </button>
          </div>
      </div>
  </div>
  <div class="output-container">
      <div class="empty" id="Generate_System_Architectural_Design_Center_Text" *ngIf="!mermaidCode && !isLoading">
          <img class="empty-img" src="assets/images/new-icons/empty.svg" />
          <span class="empty-description"> Convert textual requirements into behavioural<br> models such as UML, SysML, etc.</span>
      </div>
      <div class="output-body" >
        <div class="diagram1" *ngIf="mermaidCode">
          <markdown class="diagram" id="Generate_System_Design_Generated_Diagram" mermaid [data]="mermaidCode" style="width: 100%; height: 100%;"></markdown>
      </div>
      </div>
      <div class="output-footer" *ngIf="mermaidCode">
        <button matTooltip="Download" id="Generate_System_Design_Donwload_Button" mat-mini-fab class="download-btn" (click)="downloadUMLAsImage()">
          <mat-icon>file_download</mat-icon>
        </button>

          <button mat-raised-button id="Generate_System_Design_Copy_Code_Button" class="copy-btn" (click)="copyText()">
              <span class="copy-text">Copy Code</span>
          </button>
      </div>
  </div>
</div>


<div *ngIf="isLoading" class="loader-container">
  <div class="ring-loader">
  </div>
  <div class="loading-text">{{ currentText }}</div>
</div>




