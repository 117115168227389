<div class="container login-cont mx-0">
  <div class="row">
    <div class="col-12 img-class">
      <img height="50" src="assets/images/Teratics_logo.png" alt="Logo">
    </div>
  </div>

  <div class="row" class="auth-container">
    <div class="col-12 login-card">
        <div class="login-form login-grp">
          <mat-card>
           <span class="l-header">Login</span>
            <form [formGroup]="loginForm">
              <div class="login-group">
                <div class="pt-3">
                    <div class="mb-1">
                        <mat-label class="h-t"> Email</mat-label>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="example-full-width">
                            <input #mobileno matInput id="emailId"  name="emailId" required formControlName="sEmail" 
                                placeholder="Enter Your Email" autocomplete="off" />
                        </mat-form-field>
                    </div>
                    <mat-error *ngIf="displayMessage.sEmail">
                        {{displayMessage.sEmail}}
                    </mat-error>
                </div>
              </div>
              <div class="login-group">
                <div  class="ip-field" >
                    <div class="mb-1">
                        <mat-label class="label-font asterisk h-t" for="sPwd">Password </mat-label>
                    </div>
                    <div>
                        <mat-form-field appearance="outline" class="example-full-width">
                            <input matInput  id="sPwd" formControlName="sPwd"  [type]="hide ? 'password' : 'text'"
                                name="sPwd" required placeholder="Enter Password" autocomplete="off" />
                                <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                  <mat-icon class="eye-icon">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                                </button>

                        </mat-form-field>
                    </div>
                    <mat-error *ngIf="displayMessage.sPwd">
                      {{displayMessage.sPwd}}
                    </mat-error>
                </div>
                <div class="p-1 pt-2 text-end">
                    <a routerLink="/reset-password" id="forgotPwdLink" class="h-t" >Forgot your Password? </a>
                 </div>
              </div>
            </form>
              <div class="login-group">
               <div class="p-1">
                    <div  class="login-button">
                        <button mat-raised-button [disabled]="!loginForm.valid" id="loginBtn" name="loginBtn" class="login-button" (click)="onSubmit()" color="primary">
                                Log in
                        </button>
                    </div>
                </div>
                <div *ngIf="this.getLoading()" class="loader-container">
                    <div class="lds-dual-ring"></div>
                  </div>
              </div>
          </mat-card>
        </div>
    </div>
  </div>
</div>
