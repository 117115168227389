import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UploadComponent } from '../../shared/upload/upload.component';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';
import { ToastrService } from 'ngx-toastr';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { environment } from 'src/environment/environment';
import * as XLSX from 'xlsx';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { manropeFontBase64 } from '../manrope-font';
@Component({
  selector: 'app-requirement-comparison',
  templateUrl: './requirement-comparison.component.html',
  styleUrls: ['./requirement-comparison.component.scss']
})
export class RequirementComparisonComponent implements OnInit {

  toppings = new FormControl([]);
  toppingList: any[] = [];
  selectedFilesVisible = false;
  fileNamesDisplay: string[] = [];
  industry: any;
  selectedFile: any;
  formUploadData : any;
  uploadFilesName : any;
  requirements = [];
  texts: string[] = [];
  isLoading: boolean = false;
  intervalId: any;
  index: number = 0;
  extractedData: string = '';
  currentText: any;
  secretKey: string = environment.sKey;
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(private sanitizer: DomSanitizer , 
    private apiService: ApiService,
    private uService: UserService,
    private dialog: MatDialog, 
    private toastr: ToastrService,
    private encryptionService:EncryptService, 
    private cdr: ChangeDetectorRef) {}


  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
    });
    this.onGetReferenceFile();
  }

  getSanitizedHtml(html: string): string {
    if (html.includes('<u>')) {
      html = html.replace(/<u>/g, '<span style="color: red;">').replace(/<\/u>/g, '</span>');
    }
    if (html.includes('<strong>')) {
      html = html.replace(/<strong>/g, '<span style="color: red; font-weight: bold;">').replace(/<\/strong>/g, '</span>');
    }
    if (html.includes('<ins>')) {
      html = html.replace(/<ins>/g, '<span style="color: red;">').replace(/<\/ins>/g, '</span>');
    }
    return html;
  }

  onFileSelected(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length) {
      this.selectedFile = input.files[0]; // Store the first selected file
      this.fileNamesDisplay = Array.from(input.files).map(file => file.name);
      this.selectedFilesVisible = true;
    } else {
      this.fileNamesDisplay = [];
      this.selectedFilesVisible = false;
      this.selectedFile = null; // Clear the stored file if no files are selected
    }
  }

  get selectedToppingsDisplay(): string {
    return this.toppings.value ? this.toppings.value.join(', ') : '';  
  }


  onClear() {
    this.toppings.reset();
    this.fileNamesDisplay = [];
    this.requirements = [];
    this.selectedFile = null;
    this.selectedFilesVisible = false;
    this.fileInput.nativeElement.value = '';
  }

  onGetReferenceFile() {
    this.apiService.apiRequestGet('entity_service/reference_list').subscribe(data => {
      const result = this.encryptionService.decrypt(data.output);
      const parsedData = JSON.parse(result);
      this.toppingList = parsedData;   
    },
    err => {
      this.toastr.error('Error: Unable to load reference files data.');
    }
  );
  }

  onCompare() {
    this.requirements = [];
    this.texts = ["Comparison in Progress", "Data Comparison Underway", "Please wait"];
    const toppingsToString = this.toppings.value.join(', ');
    let formData = new FormData();
    if (this.selectedFile) {
      formData.append('req_file', this.selectedFile, this.selectedFile.name);
    }
    const queryString = `?industry_id=${this.industry}&reference_files=${encodeURIComponent(toppingsToString)}`;
    this.isLoading = true;
    this.startTextAnimation();
    this.apiService.apiRequestPost('ai_services/requirement_comparsion', queryString, formData).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result); 
        this.requirements =  parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.cdr.detectChanges();
      }
    },
      err => {
        this.toastr.clear();
        clearInterval(this.intervalId);
        this.toastr.error('Error: Data upload failed.');
        this.isLoading = false;
      }
    );
  }

  
  highlightWords(text: string): string {
    text = text.replace(/style=['"]color:red['"]/g, 'class="red-text"');
    text = text.replace(/\n/g, '<br>');
    text = text.replace(/<\/del>/g, '</div>');
    text = text.replace(/<del>/g, '<div class="change">');
    return text;
  }
  
  

  getRowColor(matchScore: number): string {
    if (matchScore === 100) {
      return '#d9ead3'; // Green
    } else if (matchScore >= 80 && matchScore < 100) {
      return '#fff2cc'; // Yellow
    } else if (matchScore >= 0 && matchScore < 80) {
      return '#FFFFFF'; // White
    } else {
      return ''; 
    }
  }
  get joinedFileNames(): string {
    return this.fileNamesDisplay.join(', ');
  }


  exportToCsv(): void {
    let csvData = this.convertToCsv(this.requirements);
    let blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    let link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'Comparison_Report.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  
  private convertToCsv(data: any[]): string {
    const replacer = (key: any, value: any) => (value === null ? '' : value);
    const header = Object.keys(data[0]).filter(key => key !== 'AI_Diff_Req_text');
  
    // Function to strip HTML tags
    const stripHtmlTags = (html: string) => {
      const doc = new DOMParser().parseFromString(html, 'text/html');
      return doc.body.textContent || "";
    };
  
    let csv = data.map((row: any) =>
      header
        .map((fieldName) => JSON.stringify(stripHtmlTags(row[fieldName]), replacer))
        .join(',')
    );
    csv.unshift(header.join(','));
    return csv.join('\r\n');
  }

  // if need to download as excel then use below code.
  exportToExcel(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(document.getElementById('comparisonTable'));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Comparison Report');
  
    XLSX.writeFile(wb, 'Comparison_Report.xlsx');
  }

  exportToPdf() {
  const data = document.getElementById('comparisonTable');
  if (data) {
      // Ensure the font is loaded and supported by jsPDF
      const fontFamily = 'Manrope'; // Make sure this font is available

      // Apply font family to the content
      data.style.fontFamily = fontFamily;

      // Create PDF instance
      const pdf = new jsPDF('p', 'mm', 'a4');

      // Add the font to jsPDF's virtual file system
      pdf.addFileToVFS('manrope-regular.ttf', manropeFontBase64);

      // Add and set the font for the PDF
      pdf.addFont('manrope-regular.ttf', 'Manrope', 'normal');
      pdf.setFont('Manrope');

      // Define the width for the content in the PDF
      const pdfWidth = pdf.internal.pageSize.getWidth() - 20; // Subtract margins
      

      // Generate PDF from HTML content
      pdf.html(data, {
          callback: function (pdf) {
              // Restore original font family
              data.style.fontFamily = fontFamily;
              pdf.save('Comparison_Report.pdf'); // Save the PDF
          },
          x: 10, // Left margin
          y: 10, // Top margin
          width: pdfWidth, // Set the width for scaling
          windowWidth: data.scrollWidth, // Use the scrollWidth of the content for scaling
      });
    }
  }


  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }
      
}
