<div class="global-container">
    <div class="input-container">
        <div class="input-content">
            <span class="input-heading m-2 mt-0"> Generate Verification Criteria </span>
            <div class="">
                <textarea [(ngModel)]="extractedData" id="Generate_Verification_Criteria_Enter_Your_Requirements" class="input-textarea" autocomplete="off"
                    placeholder="Enter your requirements"></textarea>
            </div>
            <div class="btn-group">
                <button mat-raised-button class="upload-btn" id="Upload_File_Generate_Verification_Criteria" (click)="openPopup()" *ngIf="!extractedData || extractedData == '' ">
                    <img class="upload-img" src="assets/images/new-icons/upload.svg" />
                </button>
                <button mat-raised-button class="generate-btn" id="Generate_Verification_Criteria_Generate_Button" (click)="onGenerate()" [disabled]="!extractedData">
                    <span class="gen-text"> Generate</span>
                </button>
                <button mat-raised-button class="clear-btn" id="Generate_Verification_Clear_Button" (click)="onClear()" *ngIf="extractedData || extractedData != '' ">
                    <span class="clear-text"> Clear</span>
                </button>
            </div>
        </div>
    </div>
    <div class="output-container">
        <div class="empty" id="Generate_Verification_Criteria_Center_Text" *ngIf="!softwareRequirements  && !isLoading">
            <img class="empty-img" src="assets/images/new-icons/empty.svg" />
            <span class="empty-description"> Generate verification criteria for individual <br> requirements as per
                pre-defined template</span>
        </div>
        <div class="output-body" >
            <div class="output-item" id="Generate_Verification_Criteria_Generated_Verfication_Criteria" *ngFor="let requirement of softwareRequirements">
                <div class="headings">
                    <span class="h-text">{{ requirement.heading }}</span>
                </div>
                <ul class="numbered-list">
                    <li class="l-text" *ngFor="let data of requirement.detailed_points">{{ data.value }}</li>
                </ul>
            </div>
        </div>
        <div class="output-footer" *ngIf="softwareRequirements">
            <button mat-raised-button class="copy-btn"  id="Generate_Verification_Criteria_Copy_Button"  (click)="copyText()">
                <span class="copy-text">Copy</span>
            </button>
        </div>
    </div>
</div>


<div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader">
    </div> 
    <div class="loading-text">{{ currentText }}</div>
</div>