<div class="global-container">
    <div class="input-container">
      <div class="input-content">
      
        <span class="input-heading m-2 mt-0"> Requirement </span>
        <div class *ngIf="!isJiraStory">
          <textarea [(ngModel)]="extractedData"
            id="Review_Requirments_Enter_Your_Requirements" class="input-textarea"
            autocomplete="off"
            placeholder="Enter your requirements"></textarea>
        </div>

        <div class="pt-2">
          <span class="input-heading m-2 mt-0">Verification Criteria</span>
          <div class="pt-2">
            <textarea [(ngModel)]="verificationCriteria" id="verification_criteria"
              class="input-textarea-1" autocomplete="off" placeholder="Enter your requirements"></textarea>
          </div>
        </div>  
        <div class="btn-group">
          <button mat-raised-button class="generate-btn" id="Review_Button"  [ngClass]="{'w-70a': extractedData}"
            (click)="onReview()" [disabled]="!extractedData">
            <span class="gen-text">Review</span>
          </button>
          <button mat-raised-button class="clear-btn"
            id="Review_Requirements_clear_button" (click)="onClear()"
            *ngIf="extractedData || extractedData != '' ">
            <span class="clear-text"> Clear</span>
          </button>
        </div>
      </div>
    </div>
    <div class="output-container">
      <div class="empty" id="Review_Requirements_Center_Text" *ngIf="!dataSource && !isLoading">
        <img class="empty-img" src="assets/images/new-icons/empty.svg" />
        <span class="empty-description"> Review and improve ambiguous, incomplete, incorrect,<br> illogically formatted requirements</span>
      </div>
    
      <div class="output-body" *ngIf="dataSource">
        <div class="output-item">
          <ng-container>
            <p class="h-text">Requirement</p>
            <!-- Requirement Section -->
            <div *ngIf="dataSource.requirementInfo">
              <div class="review-title-head">{{ dataSource.requirementInfo.info_key }} </div>
              <div class="output-item-review" *ngFor="let item of dataSource.requirementInfo.info_value">
                <h4><strong>{{ item.key }}</strong></h4>
                <div class="word-content">
                  <p><strong>Ambiguity:</strong> {{ item.value.Ambiguity }}</p>
                  <p><strong>Suggestion:</strong> {{ item.value.Suggestion }}</p>
                </div>
              </div>
              <!-- Display Revised Requirement Proposal -->
              <div *ngIf="dataSource.requirementInfo.info2_value">
                <div class="review-heading">
                  <div class="review-title">{{ dataSource.requirementInfo.info2 }}</div>
                  <div class="rev-btn">
                    <button (click)="toggleEdit('requirement')" aria-label="Edit proposal">
                      <mat-icon style="padding-top: 0px;">edit</mat-icon>Edit
                    </button>
                    <button (click)="copySpecificText('requirement')" aria-label="Edit proposal">
                      <mat-icon>filter_none</mat-icon>Copy
                    </button>
                  </div>
                </div>
                <div class="output-item-review-alter" *ngIf="!editingRequirement">
                  <p class="word-content">{{ dataSource.requirementInfo.info2_value }}</p>
                </div>
                <div *ngIf="editingRequirement">
                  <div class="textarea-alter">
                    <textarea [(ngModel)]="editableRequirementProposal" rows="5" class="form-control"></textarea>
                  </div>
                  <div class="edit-review">
                    <button mat-raised-button class="ok-btn" (click)="saveEdit('requirement')">
                      <span class="ok-text"> OK </span>
                    </button>
                    <button mat-raised-button class="cancel-btn" (click)="cancelEdit('requirement')">
                      <span class="cancel-text"> Cancel </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
      
            <!-- Verification Section -->
            <p class="h-text" *ngIf="dataSource.verificationInfo.info_key">Verification criteria</p>
            <div *ngIf="dataSource.verificationInfo">
              <div class="review-title-head">{{ dataSource.verificationInfo.info_key }} </div>
              <div class="output-item-review" *ngFor="let item of dataSource.verificationInfo.info_value">
                <h4><strong>{{ item.key }}</strong></h4>
                <div class="word-content">
                  <p><strong>Ambiguity:</strong> {{ item.value.Ambiguity }}</p>
                  <p><strong>Suggestion:</strong> {{ item.value.Suggestion }}</p>
                </div>
              </div>
              <!-- Display Revised Verification Proposal -->
              <div *ngIf="dataSource.verificationInfo.info2_value">
                <div class="review-heading">
                  <div class="review-title">{{ dataSource.verificationInfo.info2 }}</div>
                  <div class="rev-btn">
                    <button (click)="toggleEdit('verification')" aria-label="Edit proposal">
                      <mat-icon style="padding-top: 0px;">edit</mat-icon>Edit
                    </button>
                    <button (click)="copySpecificText('verification')" aria-label="Edit proposal">
                      <mat-icon>filter_none</mat-icon>Copy
                    </button>
                  </div>
                </div>
                <div class="output-item-review-alter" *ngIf="!editingVerification">
                  <div class="word-content" #wordContent>
                    <p><strong>Preconditions and special conditions:</strong> {{ dataSource.verificationInfo.info2_value.Preconditions }}</p>
                    <p><strong>Constraints:</strong> {{ dataSource.verificationInfo.info2_value.Constraints }}</p>
                    <p><strong>Success criteria:</strong> {{ dataSource.verificationInfo.info2_value.Success }}</p>
                  </div>
                </div>
                <div *ngIf="editingVerification">
                  <div class="textarea-alter">
                    <textarea [(ngModel)]="editableVerificationProposal" rows="5" class="form-control"></textarea>
                  </div>
                  <div class="edit-review">
                    <button mat-raised-button class="ok-btn" (click)="saveEdit('verification')">
                      <span class="ok-text"> OK </span>
                    </button>
                    <button mat-raised-button class="cancel-btn" (click)="cancelEdit('verification')">
                      <span class="cancel-text"> Cancel </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      
      
    
    </div>
    
    <!-- </div> -->
  
    <div *ngIf="isLoading" class="loader-container">
      <div class="ring-loader">
      </div>
      <div class="loading-text">{{ currentText }}</div>
    </div>