import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import mermaid from 'mermaid';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { UploadComponent } from '../../shared/upload/upload.component';
import html2canvas from 'html2canvas';
import saveAs from 'file-saver';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';
@Component({
  selector: 'app-generate-uml',
  templateUrl: './generate-uml.component.html',
  styleUrls: ['./generate-uml.component.scss']
})
export class GenerateUmlComponent implements OnInit {
  extractedData: string = "";
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  isCompare: boolean = false;
  isTable: boolean = false;
  softwareRequirements: any = null;
  categories: any
  industry: any;
  selectedValue: any = null;
  constructor(private dialog: MatDialog, private apiService: ApiService, private toastr: ToastrService, private uService: UserService,
    private cdr: ChangeDetectorRef, private encryptionService:EncryptService) {
  }


  classDiagramData: string = '';
  mermaidCode: string = '';

  groupBy: any = [
    { id: 1, name: 'UML State Diagram', value: 'UML State Diagram', testid :'Generate_System_Design_Drop_Down_UML_State_Diagram' },
    { id: 2, name: 'Systems Modeling Language (SysML)', value: 'Systems Modeling Language' , testid :'Generate_System_Design_Drop_Down_UML_Systems_Modeling_Language' }
  ]

  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
      this.selectedValue = null;
    });
    mermaid.initialize({
      securityLevel: 'loose'
    });
    mermaid.init();
  }

  onValueChange(data: any) {
    this.selectedValue = data.value;
    this.selectedValue === 2 ? this.toastr.warning('Feature under development') : null;
  }
  openPopup() {
    const uploadConfig: IUploadConfig = {
      accept: '.txt,.pdf,.docs,.docx',
      isMultiple : false
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.data = {uploadConfig}
    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(res => {
      if (res) {
        this.onGettext(res);
      }
    })
  }

  onGettext(formdata) {
    this.texts = ["File transfer underway", "Extracting data", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    this.apiService.apiRequestPost('entity_service/upload_file', '', formdata).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.extractedData = parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.toastr.success('Success !', 'Data uploaded successfully.');

      }
    },
      err => {
        this.isLoading = false;
        // clearInterval(this.intervalId);
        // this.toastr.error('Error data upload Failed.');
      }
    );

  }



  onGenerate() {
    this.mermaidCode = null;
    this.texts = ["Reviewing data", "Collecting Information", "Generating diagram"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    let set = {
      'industry_id': this.industry,
      'text': this.encryptionService.encrypt(this.extractedData)
    }
    this.apiService.apiRequestPost('ai_services/UML_generator', null, set).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.mermaidCode = parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.cdr.detectChanges();
      }
    },
      err => {
        this.isLoading = false;
        // clearInterval(this.intervalId);
        // this.toastr.error('Error data upload Failed.');
      }
    );
  }


  onClear() {
    this.extractedData = '';
    this.mermaidCode = null;
    this.selectedValue = null;
  }


  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }
  copyText() {
    const textArea = document.createElement('textarea');
    textArea.value = this.mermaidCode;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    // Optional: You can use a library like Toastr for showing success message
    this.toastr.success('Successfully  Copied.');
  }


  downloadUMLAsImage() {
    const diagramElement = document.querySelector('.output-body') as HTMLElement;

    html2canvas(diagramElement, {
        useCORS: true,
        logging: true, // Enable logging to help debug any issues
        allowTaint: true, // Allow cross-origin images to be used in the canvas
    }).then((canvas) => {
        // Convert the canvas to a data URL
        const dataUrl = canvas.toDataURL('image/png');

        // Use FileSaver to save the image
        saveAs(dataUrl, 'diagram.png');
        this.toastr.success('Diagram downloaded successfully.');
    }).catch((error) => {
        console.error('Error generating image', error);
        this.toastr.error('Error in downloading the diagram.');
    });
}

}

