<section class="p-4">
    <div class="row" *ngIf="dataSource.alternativeWords.length > 0">
        <div fxLayout="row" fxLayoutAlign="space-between">
            <h3 class="mb-1 h-text">Alternative words</h3>
            <mat-icon  style="cursor: pointer;" (click)="onClose()">close</mat-icon>
        </div>

        <div class="custom-chip-container pt-3">
            <div class="custom-chip" [class.selected]="selectedChip === item && dataSource.alternativeWords.length > 1" *ngFor="let item of dataSource.alternativeWords" (click)="onSelectChips(item)">
              {{ item }}
            </div>
          </div>
    </div>

    <div class="button-container pt-3">
        <!-- <button mat-raised-button class="custom-button ml-2 mx-2" [ngClass]="{'disable': !selectedChip }" (click)="onReplace()">
          <span class="replace-text"> Replace </span> 
        </button> -->
            <button class="copy-btn ml-2 mx-2" [ngClass]="{'selected': isReplaceButtonActive}" (click)="onReplace()" [disabled]="!isReplaceButtonActive">
              <span class="replace-text">Replace</span>
            </button>
    </div>
</section>