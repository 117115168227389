import { Directive, Input, ElementRef } from '@angular/core';

@Directive({
  selector: '[lazyLoad]'
})
export class LazyLoadDirective {
  @Input('lazyLoad') src: string;

  constructor(private el: ElementRef) {}

  ngAfterViewInit() {
    const observer = new IntersectionObserver(entries => {
      entries.forEach(({ isIntersecting }) => {
        if (isIntersecting) {
          this.el.nativeElement.src = this.src;
          observer.unobserve(this.el.nativeElement);
        }
      });
    });

    observer.observe(this.el.nativeElement);
  }
}
