import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  private renderer: Renderer2;
  private themes: string[] = ['inno', 'green','purple','blu']; // Add more themes as needed
  private currentThemeIndex: number = 0;

  constructor(private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.initializeTheme();
  }


  private initializeTheme() {
    const storedThemeIndex = +localStorage.getItem('themeIndex');
    if (!isNaN(storedThemeIndex) && storedThemeIndex < this.themes.length) {
      this.currentThemeIndex = storedThemeIndex;
    }
    this.applyTheme();
  
  }

  private applyTheme() {
    const currentTheme = this.themes[this.currentThemeIndex];

    this.themes.forEach(theme => {
      document.body.classList.remove(`${theme}-theme`);
    });

    document.body.classList.add(`${currentTheme}-theme`);
    
    localStorage.setItem('themeIndex', `${this.currentThemeIndex}`);
  }

  setTheme(theme: any) {
    if (this.themes.includes(theme)) {
      document.body.classList.remove(...this.themes.map(t => `${t}-theme`));
      document.body.classList.add(`${theme}-theme`);
      localStorage.setItem('theme', theme);
    } else {
      console.error('Invalid theme selected.');
    }
  }
  
}
