import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MaterialModule } from 'src/app/material.module';
import { UploadComponent } from './upload/upload.component';
import { FlexLayoutModule, FlexModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReviewAlternativeComponent } from './review-alternative/review-alternative.component';
import { ReviewComparisionComponent } from './review-comparision/review-comparision.component';





@NgModule({
  declarations: [
    UploadComponent,
    ReviewAlternativeComponent,
    ReviewComparisionComponent,
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FlexModule,
    FormsModule,
    ReactiveFormsModule
    

  ]
})
export class SharedModule { }
