import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isTodayOrTomorrow'
})
export class IsTodayOrTomorrowPipe implements PipeTransform {
  transform(value: any): string {
    // First, check if value is in DD/MM/YYYY format and convert it to a valid date format
    const dateParts = value.match(/(\d{2})\/(\d{2})\/(\d{4})/);
    let compareDate: Date;

    if (dateParts) {
      // If value is in DD/MM/YYYY, manually parse it
      const year = Number(dateParts[3]);
      const month = Number(dateParts[2]) - 1; // Month is 0-indexed
      const day = Number(dateParts[1]);
      compareDate = new Date(year, month, day);
    } else if (!isNaN(Date.parse(value))) {
      compareDate = new Date(value);
    } else {
      console.error('Invalid date value provided to IsTodayOrTomorrowPipe:', value);
      return 'Invalid date';
    }

    compareDate.setHours(0, 0, 0, 0);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);

    const dateFormatted = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'short', year: 'numeric' }).format(compareDate);

    if (compareDate.getTime() === today.getTime()) {
      return `Today - ${dateFormatted}`;
    } else if (compareDate.getTime() === tomorrow.getTime()) {
      return `Tomorrow - ${dateFormatted}`;
    } else if (compareDate.getTime() === yesterday.getTime()) {
      return `Yesterday - ${dateFormatted}`;
    } else {
      return dateFormatted;
    }
  }
}
