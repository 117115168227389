<div class="global-container">
    <div class="input-container">
        <div class="input-content">
            <span class="input-heading m-2 mt-0"> Summarize Requirement & QA</span>
            <div class="">
                <textarea [(ngModel)]="extractedData" id="Summarize_Requirements_Q_A_Enter_Your_Requirements" class="input-textarea" autocomplete="off"
                    placeholder="Enter your requirements"></textarea>
            </div>
            <div class="btn-group">
                <button mat-raised-button class="upload-btn"  id="Upload_File_Summarize_Requirements" (click)="openPopup()" *ngIf="!extractedData">
                    <img class="upload-img" src="assets/images/new-icons/upload.svg" />
                </button>
                <button mat-raised-button class="generate-btn" id="Summarize_Requirements_Q_A_Generate_Button" [disabled]="!extractedData" (click)="onSummarize()">
                    <span class="gen-text"> Summarize </span>
                </button>
                <button mat-raised-button class="clear-btn" id="Summarize_Requirements_Q_A_Clear_Button" (click)="onClear()" *ngIf="extractedData">
                    <span class="clear-text"> Clear</span>
                </button>
            </div>
        </div>
    </div>
    <div class="output-container">
        <div class="empty" id="Summarize_Requirements_and_Q&_A_Center_Text" *ngIf="!dataSource  && !isLoading">
            <img class="empty-img" src="assets/images/new-icons/empty.svg" />
            <span class="empty-description">Provide a brief summary of the requirements and <br> provide answers for
                user specific question</span>
        </div>
        <div class="output-body" *ngIf="dataSource">
            <div class="chat-item" #chatListContainer>
                <div class="description" id="Summarize_Requirements_Q_A_Summarized_Requirement_Content">
                    <div class="description-item">
                        <span class="first-line"> Condensed Overview of your user story......</span>
                        <span class="second-line">{{dataSource}}</span>
                    </div>
                </div>
                <div class="chat-messages">
                <ng-container class="mt-2" *ngFor="let data of chatMessages;let last = last; let index = index">

                <div class="chat-bot" *ngIf="data.sender == 0">
                    <div class="bot-icon">
                        <img src="assets/images/new-icons/bot.svg" alt="B">
                    </div>
                    <div class="chat-item-data-bot" >
                        <div class="bot-content" [id]="'message-' + (index+1)">
                            <span *ngIf="!last || newMessage == ''" [innerHTML]="data.message"> </span>
                            <ngx-typed-js  *ngIf="newMessage && last" [strings]="[newMessage]" [typeSpeed]="5"
                                [showCursor]="false">
                                <span class="typing"></span>
                            </ngx-typed-js>
                        </div>
                    </div>
                </div> 

                <div class="chat-user" *ngIf="data.sender == 1">
                    <div class="chat-item-data-user">
                        <div class="user-content" [id]="'message-' + (index+1)">
                            {{data.message}}
                        </div>
                    </div>
                    <div class="user-icon">
                        <img src="assets/images/new-icons/user.svg" alt="B">
                    </div>
                </div>



                </ng-container>
            </div>
            </div>

            <div class="chat-footer1">
                <div class="box" *ngIf="chatloading">
                    <div class="container">
                        <span class="circle"></span>
                        <span class="circle"></span>
                        <span class="circle"></span>
                        <span class="circle"></span>
                    </div>
                </div>
                <div class="chat-input">
                    <div class="textarea-container">
                        <textarea class="chat-textarea" id="Summarize_Requirements_Please_Enter_The_Query_Here" autocomplete="off" placeholder="Please enter the query here"
                            [(ngModel)]="chatInput" (keydown)="onKeyDown($event)" (keyup.enter)="onChat()"
                            [disabled]="onDisable"></textarea>
                        <img class="send-img" id="Summarize_Requirements_Please_Enter_The_Query_Here_Send_Button" src="assets/images/new-icons/send.svg"
                            [ngClass]="{'not-clickable': onDisable}" (click)="onChat()">
                    </div>
                </div>
            </div>
        </div>
        <div class="output-footer" *ngIf="dataSource">
            <button mat-raised-button class="copy-btn" id="Summarize_Requirements_Copy_Summary" (click)="copyText()">
                <span class="copy-text">Copy Summary</span>
            </button>
        </div>
    </div>
</div>


<div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader">
    </div>
    <div class="loading-text">{{ currentText }}</div>
</div>