<div class="global-container">
  <div class="input-container">
    <div class="input-content">
      <span class="input-heading m-2 mt-0"> Ticket Id </span>
      <div class="ticket pb-2">
        <div class="input-container1">
          <input type="text" id="Review_Requirements_Ticket_ID"
            [(ngModel)]="storyId" class="ticket-ip"
            placeholder="Enter Ticket Number">
          <button id="Review_Requirements_Ticket_ID_send_Button"
            matTooltip="Fetch" mat-mini-fab
            (click)="getJiraStory()"
            [disabled]="!storyId || storyId.trim().length === 0">
            <mat-icon matSuffix>send</mat-icon>
          </button>

        </div>
      </div>
      <div class="or-divider">
        <span class="or-text">OR</span>
      </div>
      <span class="input-heading m-2 mt-0"> Review Requirement </span>
      <div class *ngIf="!isJiraStory">
        <textarea [(ngModel)]="extractedData"
          id="Review_Requirments_Enter_Your_Requirements" class="input-textarea"
          autocomplete="off"
          placeholder="Enter your requirements"></textarea>
      </div>
      <div class="input-textarea1" *ngIf="isJiraStory">
        <div id="Review_Requirments_Enter_Your_Requirements"
          [innerHTML]="extractedData"></div>
      </div>

      <div class="mt-3">
        <mat-form-field appearance="outline"
          class="custom-mat-form-field drop1">
          <mat-label>Select Review</mat-label>
          <mat-select id="Group_By_Dropdown" [(value)]="selectedValue"
            (selectionChange)="onValueChange($event)">
            <mat-option *ngFor="let data of groupBy" [value]="data.value"
              [id]="data.id">
              {{data.value}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="btn-group">
        <button mat-raised-button class="upload-btn"
          id="Upload_File_Review_Requirements" (click)="openPopup()"
          *ngIf="!extractedData || extractedData == '' ">
          <img class="upload-img" src="assets/images/new-icons/upload.svg" />
        </button>
        <button mat-raised-button class="generate-btn" id="Review_Button"
          (click)="onReview()" [disabled]="!extractedData">
          <span class="gen-text">Review</span>
        </button>
        <button mat-raised-button class="clear-btn"
          id="Review_Requirements_clear_button" (click)="onClear()"
          *ngIf="extractedData || extractedData != '' ">
          <span class="clear-text"> Clear</span>
        </button>
      </div>
    </div>
  </div>
  <div class="output-container">
    <div class="empty" id="Review_Requirements_Center_Text"
      *ngIf="!dataSource  && !isLoading">
      <img class="empty-img" src="assets/images/new-icons/empty.svg" />
      <span class="empty-description"> Review and improve ambiguous, incomplete,
        incorrect,<br> illogically formatted requirements</span>
    </div>
    <div class="output-body" *ngIf="isGraph">
      <div class="output-item">
        <ng-container *ngIf="dataSource">
          <div class="chart-card" id="Review_Requirements_Score_Card">
            <div class="card">
              <div class="chart" id="overallscore">
                <apx-chart class="chart-text-style"
                  [series]="overallscoreoptions.series"
                  [chart]="overallscoreoptions.chart"
                  [plotOptions]="overallscoreoptions.plotOptions"
                  [labels]="overallscoreoptions.labels"
                  [stroke]="overallscoreoptions.stroke"
                  [fill]="overallscoreoptions.fill"></apx-chart>
              </div>
            </div>
            <div class="cards">

              <div class="progress-container">
                <div *ngFor="let head of scoreHeading; let i = index"
                  class="progress-item">
                  <label for="semantics">{{ head }}</label>
                  <mat-progress-bar [id]="head" mode="determinate"
                    [value]="dataSource.item_ratings[i]"></mat-progress-bar>
                  <span class="progress-value">{{ dataSource.item_ratings[i]
                    }}%</span>
                </div>
              </div>
            </div>
          </div>
          <div class="chart-card1">
            <div class="card1">
              <div class="heading">
                <span class="output-wrapper1 px-2" *ngIf="isCompare"><mat-icon
                    (click)="openComparison()"
                    id="Review_Requirements_Compare">compare</mat-icon> </span>
              </div>
              <ng-container class="highlight-conatins">
                <p *ngIf="dataSource?.user_content"
                  id="Review_Requirements_changes_in_Requirements"
                  class="word-content m-3" #content
                  [innerHTML]="highlightWords(dataSource?.user_content)"></p>
              </ng-container>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="output-body" *ngIf="!isGraph">
      <div class="output-item">
        <ng-container *ngIf="dataSource">
          <!-- Display Ambiguities and Missing Information -->
          <div *ngIf="dataSource.info_value">
            <div class="review-title-head">{{ dataSource.info_key }} </div>
            <div class="output-item-review"
              *ngFor="let item of dataSource.info_value">
              <h4><strong>{{ item.key }}</strong></h4>
              <div class="word-content">
                <p><strong>Ambiguity:</strong> {{ item.value.Ambiguity }}</p>
                <p><strong>Suggestion:</strong> {{ item.value.Suggestion }}</p>
              </div>
            </div>
          </div>
          <!-- Display Revised Requirement Proposal -->
          <div *ngIf="dataSource.info2_value">
            <div class="review-heading">
              <div class="review-title">{{ dataSource.info2 }}</div>
              <div>
                <button mat-icon-button (click)="toggleEdit()"
                  aria-label="Edit proposal">
                  <mat-icon style="padding-top: 0px;">edit</mat-icon>
                </button>
              </div>
            </div>
            <div class="output-item-review-alter" *ngIf="!editingProposal">
              <p class="word-content">{{ dataSource.info2_value }}</p>
            </div>
            <div *ngIf="editingProposal">
              <div  class="textarea-alter">
                <textarea [(ngModel)]="editableProposal" rows="5"
                  class="form-control"></textarea>
              </div>
              <div class="edit-review">

                <button mat-raised-button class="ok-btn" (click)="saveEdit()">
                  <span class="ok-text"> OK </span>
                </button>

                <button mat-raised-button class="cancel-btn"
                  (click)="cancelEdit()">
                  <span class="cancel-text"> Cancel </span>
                </button>

              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="output-footer" *ngIf="dataSource">
      <button mat-raised-button class="revert-btn"
        id="Review_Requirement_revert_all_button" (click)="onRevert()"
        *ngIf="isCompare||isGraph">
        <span class="copy-text">Revert All</span>
      </button>
      <button mat-raised-button class="change-btn"
        id="Review_Requirement_change_all_button" *ngIf="isGraph"
        (click)="replaceAllWithAlternatives()">
        <span class="change-text">Change All</span>
      </button>
      <button mat-raised-button class="copy-btn"
        id="Review_Requirement_Copy_Button" (click)="copyText()"
        *ngIf="hasModifications||!isGraph">
        <span class="copy-text">Copy</span>
      </button>
    </div>
  </div>
  <!-- </div> -->

  <div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader">
    </div>
    <div class="loading-text">{{ currentText }}</div>
  </div>