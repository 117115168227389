import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/core/services/api/api.service';
import { UploadComponent } from '../../shared/upload/upload.component';
import { UserService } from 'src/app/core/services/user/user.service';
import { EncryptService } from 'src/app/core/services/encrypt-decrypt/encrypt.service';
import { IUploadConfig } from 'src/app/core/utils/interface/shared.interface';

@Component({
  selector: 'app-generate-verification',
  templateUrl: './generate-verification.component.html',
  styleUrls: ['./generate-verification.component.scss']
})
export class GenerateVerificationComponent {
  extractedData: string = "";
  currentText: string;
  intervalId: any;
  index: number = 0;
  texts: string[] = [];
  isLoading: boolean = false;
  isCompare: boolean = false;
  isTable: boolean = false;
  softwareRequirements: any = null;
  categories: any
  industry: any;
  constructor(private dialog: MatDialog, private apiService: ApiService, private toastr: ToastrService, private uService: UserService,
    private cdr: ChangeDetectorRef,private encryptionService:EncryptService) {
  }

  ngOnInit(): void {
    this.uService.industry$.subscribe(data => {
      this.industry = data;
    });
  }

  onTabel() {
    this.isTable = !this.isTable;
  }


  onNodes() {
    this.toastr.clear();
    this.toastr.info('Feature under development', 'Coming soon!.');
  }

  openPopup() {
    const uploadConfig: IUploadConfig = {
      accept: '.txt,.pdf,.docs,.docx',
      isMultiple : false
    }
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = 'max-content'
    dialogConfig.panelClass = 'info-dialog'
    dialogConfig.data = {uploadConfig}
    this.dialog.open(UploadComponent, dialogConfig).afterClosed().subscribe(res => {
      if (res) {
        this.onGettext(res);
      }
    })
  }

  onGettext(formdata) {
    this.texts = ["File transfer underway", "Extracting data", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    this.apiService.apiRequestPost('entity_service/upload_file', '', formdata).subscribe((data: any) => {
      if (data) {
        const result = this.encryptionService.decrypt(data.output);
        const parsedData = JSON.parse(result);
        this.extractedData = parsedData;
        this.isLoading = false;
        clearInterval(this.intervalId);
        this.toastr.clear();
        this.toastr.success('Success !', 'Data uploaded successfully.');

      }
    },
      err => {
        this.isLoading = false;
        clearInterval(this.intervalId);
        // this.toastr.clear();
        // this.toastr.error('Error data upload Failed.');
      }
    );

  }

  getRequirementsByCategory(category: string) {
    return this.softwareRequirements.filter(req => req.category === category);
  }

  onGenerate() {
    this.softwareRequirements = null;
    this.texts = ["Reviewing data", "Collecting Information", "Please wait"];
    this.isLoading = true;
    this.index = 0;
    this.startTextAnimation();
    let set = {
        'industry_id': this.industry,
        'text': this.encryptionService.encrypt(this.extractedData)
    }
    this.apiService.apiRequestPost('ai_services/verification_ceriteria', null, set).subscribe(
        (data: any) => {
            if (data) {
                const result = this.encryptionService.decrypt(data.output);
                const parsedData = JSON.parse(result);
                this.softwareRequirements = parsedData;
                this.categories = [...new Set(this.softwareRequirements.map(x => x.category))];
                this.isLoading = false;
                clearInterval(this.intervalId);
                this.cdr.detectChanges();
            }
        },
        err => {
            this.isLoading = false;
            clearInterval(this.intervalId);
            // this.toastr.clear();
            // this.toastr.error('Error data upload Failed.');
        }
    );
  }



  onClear() {
    this.extractedData = '';
    this.softwareRequirements = null;
  }


  startTextAnimation() {
    this.changeText();
    this.intervalId = setInterval(() => {
      this.changeText();
    }, 8000);
  }


  changeText() {
    if (this.index < this.texts.length) {
      this.currentText = this.texts[this.index];
      this.index++;
    } else {
      this.index = 0;
      this.currentText = this.texts[this.index];
    }
  }


  copyText() {
    let textToCopy = '';

    if (this.softwareRequirements && this.softwareRequirements.length > 0) {
      this.softwareRequirements.forEach(requirement => {
        textToCopy += `${requirement.heading}\n`;
        requirement.detailed_points.forEach(point => {
          textToCopy += ` - ${point.value}\n`;
        });

        textToCopy += '\n';
      });
    } else {
      console.error('No content found to copy.');
      return;
    }
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    this.toastr.clear();
    this.toastr.success('Successfully copied content.');
  }
}
