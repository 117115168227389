import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { IUser, UserService } from '../user/user.service';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router,private _uService: UserService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,) {
        const queryParams = route.queryParams; 
        const secretKey = 'InBo0n90DFiNjklUY';
        if(queryParams.auth){
            sessionStorage.setItem( 'session_id', queryParams.session);
            sessionStorage.setItem( 'authorization', queryParams.auth);
            let datauser = {
                email: this._uService.decrypt(queryParams.email,secretKey),
                first_name: this._uService.decrypt(queryParams.first_name,secretKey),
                last_name: this._uService.decrypt(queryParams.last_name,secretKey),
                profile_pic: this._uService.decrypt(queryParams.profile_pic,secretKey),
                user_id: queryParams.user_id,
                user_type: this._uService.decrypt(queryParams.user_type,secretKey)
              }
             sessionStorage.setItem( 'currentUser', JSON.stringify(datauser));
              let obj=<IUser>{};
              obj.currentUser = JSON.stringify(datauser) 
              this._uService.setmode(obj);
        }
      
        if (sessionStorage.getItem('currentUser')) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['']);
        return false;
    }
}