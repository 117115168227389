<div class="global-container">
    <div class="input-container">
        <div class="input-content">
            <span class="input-heading m-2 mt-0"> Classify Requirement </span>
            <div class="">
                <textarea [(ngModel)]="extractedData" id="Classify_Requirements_Enter_Your_Requirements" class="input-textarea" autocomplete="off"
                    placeholder="Enter your requirements"></textarea>
            </div>
            <div class="btn-group">
                <button mat-raised-button class="upload-btn" id="Upload_File_Classify_Requirements" (click)="openPopup()" *ngIf="!extractedData">
                    <img class="upload-img" src="assets/images/new-icons/upload.svg" />
                </button>
                <button mat-raised-button class="generate-btn" id="Classify_Requirements_Classify_Button" [disabled]="!extractedData" (click)="onClassify()">
                    <span class="gen-text"> Classify</span>
                </button>
                <button mat-raised-button class="clear-btn" id="Classify_Requirements_Clear_Button" (click)="onClear()" *ngIf="extractedData">
                    <span class="clear-text"> Clear</span>
                </button>
            </div>
        </div>
    </div>
    <div class="output-container">
        <div class="empty" id="Classify_Requirements_Center_Text" *ngIf="!softwareRequirements && !isLoading" >
            <img class="empty-img" src="assets/images/new-icons/empty.svg" />
            <span class="empty-description"> Classify requirements into different categories <br>
                (Software , Calibration , Non-Functional, etc.)
            </span>
        </div>
        <div class="output-body">
            <div class="img-change" id="Classify_Requirements_classified_tableview">
                <img src="assets/images/frame1.svg" *ngIf="!isTable && softwareRequirements" (click)="onTabel()" />
                <img src="assets/images/frame2.svg" *ngIf="isTable && softwareRequirements" (click)="onTabel()" />
            </div>
            <div *ngIf="!isTable && softwareRequirements">
                <div class="output-item" id="Classify_Requirements_classified_Description" *ngFor="let category of categories">
                    <div class="headings">
                        <span class="h-text">{{ category }}</span>
                    </div>
                    <ul class="numbered-list">
                        <li class="l-text" *ngFor="let requirement of getRequirementsByCategory(category)">{{
                            requirement.requirement }}</li>
                    </ul>
                </div>
            </div>

            <div *ngIf="isTable && softwareRequirements"  class="example-container pt-2">
                <table mat-table [dataSource]="softwareRequirements" class=" pt-4"  id="Classify_Requirements_classified_table">

                    <!-- Requirement Column -->
                    <ng-container matColumnDef="requirement">
                        <th mat-header-cell *matHeaderCellDef class="header-text"> Requirement </th>
                        <td mat-cell *matCellDef="let element" class="requirement-column req"> {{element.requirement}}
                        </td>
                    </ng-container>

                    <!-- Category Column -->
                    <ng-container matColumnDef="category">
                        <th mat-header-cell *matHeaderCellDef class="header-text"> Category </th>
                        <td mat-cell *matCellDef="let element" class="category-column">
                            <mat-chip [ngClass]="element.cssclass">
                                {{ element.category }}
                            </mat-chip>
                        </td>
                    </ng-container>

                    <!-- Criticality Column -->
                    <ng-container matColumnDef="criticality">
                        <th mat-header-cell *matHeaderCellDef class="header-text"> Criticality </th>
                        <td mat-cell *matCellDef="let element" class="criticality-column req">
                            <span class="dot" [ngStyle]="{'background-color': getDotColor(element.criticality)}"></span>
                            {{ element.criticality }}
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
            </div>

        </div>
        <div class="output-footer" *ngIf="softwareRequirements">
            <button mat-raised-button class="copy-btn" id="Classify_Requirements_Copy_Button" *ngIf="!isTable" (click)="copyText()">
                <span class="copy-text">Copy</span>
            </button>
        </div>
    </div>
</div>


<div *ngIf="isLoading" class="loader-container">
    <div class="ring-loader">
    </div>
    <div class="loading-text">{{ currentText }}</div>
</div>